import {
    API
} from '../../api';

const state = {
    events: [],
    loading: false,
}

// getters
const getters = {

}

// actions
const actions = {
    loadEvents(context) {
        context.commit('setLoading', true);
        API.get_spaces().then((r) => {
            context.commit('setEvents', r);
            context.commit('setLoading', false);
        }).catch(() => {
            context.commit('setEvents', []);
            context.commit('setLoading', false);
        });
    },
}

// mutations
const mutations = {
    setEvents(state, events) {
        state.events = events;
    },
    setLoading(state, l) {
        state.loading = l;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}