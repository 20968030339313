<template>
  <div id="splash">
    <Header>
      <div class="big-text">xoxo doesn't work on Safari.</div>
      <div class="small-text">Apple doesn't like WebRTC, use Chrome or Firefox :(</div>
    </Header>
  </div>
</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";

export default {
  name: "Browser",
  components: {
    Header
  }
};
</script>

<style lang="scss">
.big-text {
  text-align: center;
  padding: 3rem;
  font-family: "Playfair Display";
  font-size: 35px;
}

.small-text {
  text-align: center;
  font-size: 15px;
  color: grey;
}
</style>
