<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: $uiFont;
  font-size: $uiFontSize;
  // background-color: black;
}

#app {
  background-color: white;
  // width: 100vw;
  // min-height: 100vh;
  // overflow-x: hidden;
  // border-radius: 20px;
  // animation: border-bounce 1s;
  // animation-fill-mode: forwards;
}

@keyframes border-bounce {
  0% {
    border-radius: 0px;
  }
  50% {
    border-radius: 50px;
  }
  100% {
    border-radius: 20px;
  }
}

.field {
  margin-bottom: 1rem;
}

.label {
  color: #000000;
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.field input[type="text"],
input[type="password"] {
  font-size: 14px;
  color: #35495e;
  width: 100%;
  border: 1px solid #e8e8e8;
  background: #fff;
  outline: none;
  border-radius: 5px;
  padding: 0.8rem 0.8rem;
  transition: 0.1s ease border;
  box-sizing: border-box;
}

.field input:focus {
  border: 2px solid #c5ac9c;
}

.field .multi {
  width: 100%;
}

.radio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.radio input {
  margin-right: 1rem;
  cursor: pointer;
}

// /* Turn on custom 8px wide scrollbar */
// ::-webkit-scrollbar {
//   width: 8px; /* 1px wider than Lion. */
//   /* This is more usable for users trying to click it. */
//   background-color: rgba(0, 0, 0, 0);
//   border-radius: 100px;
// }
// /* hover effect for both scrollbar area, and scrollbar 'thumb' */
// ::-webkit-scrollbar:hover {
//   background-color: rgba(0, 0, 0, 0.09);
// }

// /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
// ::-webkit-scrollbar-thumb:vertical {
//   /* This is the EXACT color of Mac OS scrollbars.
//      Yes, I pulled out digital color meter */
//   background: rgba(57, 49, 83, 0.5);
//   border-radius: 100px;
// }
// ::-webkit-scrollbar-thumb:vertical:active {
//   background: rgba(57, 52, 99, 0.61); /* Some darker color when you click it */
//   border-radius: 100px;
// }

// * {
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//   -webkit-tap-highlight-color: transparent;
// }

.splitpanes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}
.splitpanes--vertical {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.splitpanes--horizontal {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.splitpanes--dragging * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.splitpanes__pane {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.splitpanes--vertical .splitpanes__pane {
  -webkit-transition: width 0.2s ease-out;
  transition: width 0.2s ease-out;
}
.splitpanes--horizontal .splitpanes__pane {
  -webkit-transition: height 0.2s ease-out;
  transition: height 0.2s ease-out;
}
.splitpanes--dragging .splitpanes__pane {
  -webkit-transition: none;
  transition: none;
}
.splitpanes__splitter {
  -ms-touch-action: none;
  touch-action: none;
}
.splitpanes--vertical > .splitpanes__splitter {
  min-width: 1px;
  cursor: col-resize;
}
.splitpanes--horizontal > .splitpanes__splitter {
  min-height: 1px;
  cursor: row-resize;
}
.splitpanes.default-theme .splitpanes__pane {
  background-color: #2a2338;
}
.splitpanes.default-theme .splitpanes__splitter {
  background-color: black;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: rgb(54, 54, 54);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.splitpanes.default-theme .splitpanes__splitter:hover:after,
.splitpanes.default-theme .splitpanes__splitter:hover:before {
  background-color: rgb(129, 129, 129);
}
.splitpanes.default-theme .splitpanes__splitter:first-child {
  cursor: auto;
}
.default-theme.splitpanes .splitpanes .splitpanes__splitter {
  z-index: 1;
}
.default-theme.splitpanes--vertical > .splitpanes__splitter,
.default-theme .splitpanes--vertical > .splitpanes__splitter {
  width: 7px;
  border-left: 1px solid black;
  margin-left: -1px;
}
.default-theme.splitpanes--vertical > .splitpanes__splitter:after,
.default-theme .splitpanes--vertical > .splitpanes__splitter:after,
.default-theme.splitpanes--vertical > .splitpanes__splitter:before,
.default-theme .splitpanes--vertical > .splitpanes__splitter:before {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 30px;
}
.default-theme.splitpanes--vertical > .splitpanes__splitter:before,
.default-theme .splitpanes--vertical > .splitpanes__splitter:before {
  margin-left: -2px;
}
.default-theme.splitpanes--vertical > .splitpanes__splitter:after,
.default-theme .splitpanes--vertical > .splitpanes__splitter:after {
  margin-left: 1px;
}
.default-theme.splitpanes--horizontal > .splitpanes__splitter,
.default-theme .splitpanes--horizontal > .splitpanes__splitter {
  height: 7px;
  border-top: 1px solid #eee;
  margin-top: -1px;
}
.default-theme.splitpanes--horizontal > .splitpanes__splitter:after,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:after,
.default-theme.splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:before {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 30px;
  height: 1px;
}
.default-theme.splitpanes--horizontal > .splitpanes__splitter:before,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:before {
  margin-top: -2px;
}
.default-theme.splitpanes--horizontal > .splitpanes__splitter:after,
.default-theme .splitpanes--horizontal > .splitpanes__splitter:after {
  margin-top: 1px;
}

.tooltip {
  display: block !important;
  z-index: 10000;
  font-size: 0.7em;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
