<template>
  <div>
    <Header :dark="true" :noflow="true">
      <div class="space" v-if="$store.state.space.space.room">
        <splitpanes class="default-theme" @resize="paneResize($event[0].size)">
          <pane :size="paneSize" min-size="20">
            <div class="left-pane">
              <div class="butts">
                <!-- <div class="room-title">
                  <div class="room-is">This room is:</div>
                  <div class="room-name">
                    {{ $store.state.space.space.room.name }}
                    <span
                      class="settings2"
                      v-if="$store.state.space.space.admin && false"
                    >
                      <font-awesome-icon icon="cog"></font-awesome-icon>
                    </span>
                  </div>
                </div>-->
                <div class="toolbar">
                  <!-- <BigButton
                    v-tooltip="'Create New Link'"
                    color="blue"
                    v-if="
                      $store.state.space.space.admin ||
                      $store.state.space.space.user.roles.some(
                        (x) => x.create_links
                      )
                    "
                    @click="newLinkOpen = true"
                  >
                    <font-awesome-icon icon="link"></font-awesome-icon>
                  </BigButton>-->
                  <div style="width: 0.5rem"></div>
                  <!-- <BigButton color="black" v-tooltip="'Broadcast'">
                    <font-awesome-icon icon="bullhorn"></font-awesome-icon>
                  </BigButton>
                  <div style="width: 0.5rem"></div>-->
                  <BigButton color="green" @click="inviteShow = true">Invite People</BigButton>
                  <div style="width: 0.5rem"></div>
                  <BigButton
                    color="dark-pink"
                    v-if="
                      $store.state.space.space.admin ||
                      $store.state.space.space.user.roles.some(
                        (x) => x.create_booths || x.admin
                      )
                    "
                    @click="newBoothOpen = true"
                  >New Table</BigButton>
                  <div style="width: 0.5rem"></div>
                  <BigButton
                    v-tooltip="'More Actions'"
                    color="blue"
                    @click="$refs.moreActionsMenu.open($event, '')"
                  >
                    <font-awesome-icon icon="ellipsis-v"></font-awesome-icon>
                  </BigButton>

                  <ContextMenu ref="moreActionsMenu">
                    <template>
                      <div
                        v-if="$store.state.space.space.admin || $store.state.space.space.user.roles.some((x) => x.create_links)"
                        @click="newLinkOpen = true"
                      >
                        <i>
                          <font-awesome-icon icon="link"></font-awesome-icon>
                        </i>
                        <span>Add A New Link</span>
                      </div>
                      <div @click="deleteInactive">
                        <i>
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </i>
                        <span>Delete All Inactive Tables</span>
                      </div>
                    </template>
                  </ContextMenu>
                </div>
                <!-- <div class="queue-bar">
                  
                </div>-->
                <div class="table-search">
                  <input
                    type="text"
                    class="search-box-text"
                    v-model="filterTable"
                    placeholder="Search people, tables"
                  />
                </div>
              </div>
              <div class="sort-by">
                <div class="sort-by-wrapper">
                  <span class="sort-by-prompt">Sort By:</span>
                  <span class="sort-by-text" @click="$refs.sortByMenu.open($event, '')">
                    {{ sortBoothsBy }}
                    <font-awesome-icon icon="caret-down"></font-awesome-icon>
                  </span>
                </div>
                <div class="sound-control-wrapper">
                  <font-awesome-icon
                    icon="volume-up"
                    v-tooltip="'Turn OFF sounds'"
                    v-if="sounds"
                    @click="sounds = !sounds"
                  ></font-awesome-icon>
                  <font-awesome-icon
                    icon="volume-mute"
                    v-tooltip="'Turn ON sounds'"
                    v-if="!sounds"
                    @click="sounds = !sounds"
                  ></font-awesome-icon>
                </div>
              </div>
              <!-- <div class="alltables">Tables in this room:</div> -->
              <div class="booths">
                <div class="booths-wrapper">
                  <div class="no-booths" v-if="tables.length == 0">No Tables 😮</div>
                  <div class="booth">
                    <LinkS
                      class="link"
                      :link="link"
                      v-for="link in $store.state.space.space.links"
                      :key="'link' + link.id"
                    ></LinkS>
                    <Booth :booth="booth" v-for="booth in tables" :key="booth.id" @leave="leave"></Booth>
                  </div>
                  <div class="clear-search" v-if="filterTable != ''">
                    <BigButton color="dark-pink" @click="filterTable = ''">Clear Search</BigButton>
                  </div>
                </div>
              </div>
            </div>
          </pane>
          <pane class="right-pane" :size="100 - parseFloat(paneSize)" min-size="30">
            <div class="mid-wrapper">
              <div class="queue-wrapper" v-if="queueShowing">
                <div class="queue-close" @click="queueShowing = false">x</div>
                <div class="queue-title">
                  Queue ({{
                    $store.state.space.space.queue.locked
                      ? "Locked"
                      : "Unlocked"
                  }})
                </div>
                <div class="queue-controls">
                  <BigButton
                    color="blue"
                    @click="addQueueOpen = true"
                    :disabled="$store.state.space.space.queue.locked"
                    v-if="
                      ($store.state.space.space.admin ||
                        $store.state.space.space.user.roles.some(
                          (x) => x.use_queue || x.admin
                        )) &&
                      !$store.state.space.space.queue.am_i_in_queue
                    "
                  >Add Myself To Queue</BigButton>
                  <BigButton
                    color="dark-pink"
                    @click="removeMeQueue"
                    v-if="
                      ($store.state.space.space.admin ||
                        $store.state.space.space.user.roles.some(
                          (x) => x.use_queue || x.admin
                        )) &&
                      $store.state.space.space.queue.am_i_in_queue
                    "
                  >Remove Myself From Queue</BigButton>
                  <BigButton
                    color="blue"
                    @click="toggleLocked"
                    v-if="
                      ($store.state.space.space.admin ||
                        $store.state.space.space.user.roles.some(
                          (x) => x.manage_queue || x.admin
                        )) &&
                      !$store.state.space.space.queue.locked
                    "
                  >Lock</BigButton>
                  <BigButton
                    color="blue"
                    @click="toggleLocked"
                    v-if="
                      ($store.state.space.space.admin ||
                        $store.state.space.space.user.roles.some(
                          (x) => x.manage_queue || x.admin
                        )) &&
                      $store.state.space.space.queue.locked
                    "
                  >Unlock</BigButton>
                  <BigButton
                    color="blue"
                    @click="showClear = true"
                    v-if="
                      $store.state.space.space.admin ||
                      $store.state.space.space.user.roles.some(
                        (x) => x.manage_queue || x.admin
                      )
                    "
                  >Clear</BigButton>
                </div>
                <div class="queue-table">
                  <table border="0">
                    <thead>
                      <td>Student</td>
                      <td>Claimed By</td>
                      <td>Note</td>
                      <td>Timing</td>
                      <td></td>
                    </thead>
                    <tbody>
                      <tr v-for="member in queueMembers()" :key="member.user.id">
                        <td :class="{ strike: member.claimed }">
                          <UserBubble :sleek="true" :user="member.user"></UserBubble>
                        </td>
                        <td>
                          <UserBubble :sleek="true" :user="member.claimed_by" v-if="member.claimed"></UserBubble>
                        </td>
                        <td class="timing">{{ member.subtext }}</td>
                        <td class="timing">{{ member.timing }}</td>
                        <td class="queue-entry-controls">
                          <BigButton
                            color="dark-pink"
                            @click="claimMember(member)"
                            v-if="
                              ($store.state.space.space.admin ||
                                $store.state.space.space.user.roles.some(
                                  (x) => x.manage_queue || x.admin
                                )) &&
                              !member.claimed
                            "
                          >Claim</BigButton>
                          <BigButton
                            color="blue"
                            @click="unclaimMember(member)"
                            v-if="
                              ($store.state.space.space.admin ||
                                $store.state.space.space.user.roles.some(
                                  (x) => x.manage_queue || x.admin
                                )) &&
                              member.claimed
                            "
                          >Unclaim</BigButton>
                          <BigButton
                            color="dark-pink"
                            @click="removeFromQueue(member)"
                            v-if="
                              $store.state.space.space.admin ||
                              $store.state.space.space.user.roles.some(
                                (x) => x.manage_queue || x.admin
                              )
                            "
                          >Remove</BigButton>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="jitsi">
                <div class="tabs-wrapper" v-if="$store.state.space.space.uuid">
                  <div class="tab-bar">
                    <div
                      class="tab first"
                      @click="currentTab = -1"
                      :class="{ selected: currentTab == -1 }"
                    >Video</div>
                    <div
                      class="tab"
                      :class="{ selected: currentTab == tab.id }"
                      v-for="tab in $store.state.space.space.tabs"
                      :key="tab.id"
                      @click="currentTab = tab.id"
                    >
                      {{ tab.name }}
                      <font-awesome-icon
                        @click="deleteTab(tab)"
                        icon="times"
                        v-tooltip="'Delete Tab'"
                      ></font-awesome-icon>
                    </div>
                    <div class="new-tab" @click="$refs.newTabMenu.open($event, '')">+</div>
                  </div>
                  <div class="tab-contents">
                    <div class="tab-content" :class="{ selected: currentTab == -1 }">
                      <VidChat
                        :visible="true"
                        v-if="$store.state.space.space.uuid"
                        :uuid="$store.state.space.space.uuid"
                        @leave="leave"
                      ></VidChat>
                    </div>
                    <div
                      class="tab-content framey"
                      :class="{ selected: currentTab == tab.id }"
                      v-for="tab in $store.state.space.space.tabs"
                      :key="tab.id"
                    >
                      <iframe
                        :src="tab.link"
                        allow="camera;microphone;geolocation;midi;encrypted-media;clipboard-write"
                        frameborder="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pane>
        </splitpanes>
        <div class="members">
          <div class="search-box">
            <input
              type="text"
              class="search-box-text"
              v-model="filterMembersQuery"
              placeholder="Search people, roles"
            />
          </div>
          <div class="role-section" v-for="role in onlineRoles" :key="role.role.id">
            <div class="role-header">{{ role.role.name }}&mdash;{{ role.members.length }}</div>
            <div class="role-list">
              <div class="member" :key="member.id" v-for="member in role.members">
                <UserBubble :sleek="true" :user="member"></UserBubble>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rooms" v-else>
        <div class="room-title2">Rooms</div>
        <div class="rooms-body">
          <div
            class="room new-room"
            @click="newRoomOpen = true"
            v-if="$store.state.space.space.admin"
          >
            <div class="new-room-plus">
              <font-awesome-icon icon="plus"></font-awesome-icon>
            </div>
            <div class="room-name">New Room</div>
          </div>
          <div
            class="room"
            v-for="room in $store.state.space.space.rooms"
            :key="room.id"
            @click="joinRoom(room)"
          >
            <div class="room-name">{{ room.name }}</div>
            <div class="join">
              <BigButton color="dark-pink">Join</BigButton>
            </div>
          </div>
        </div>
      </div>

      <SmallModal
        :open="$store.state.space.disconnected"
        title="Connection Lost"
        :yesButton="false"
        :noButton="false"
      >
        <div class="spinner-container">
          <Spinner></Spinner>
        </div>Either you lost internet access or our servers went down.
        <br />
        <br />We will automatically detect once you're back online!
      </SmallModal>

      <SmallModal
        :open="showClear == true"
        title="Are you sure?"
        @yes="
        showClear = false;
        clearQueue();
        "
        @close="showClear = false"
        yesText="Clear Queue"
      >Are you sure you want to clear the queue?</SmallModal>

      <SmallModal
        :open="error != ''"
        title="Error"
        noText="Oof Okay"
        :yesButton="false"
        @close="error = ''"
      >{{ error }}</SmallModal>

      <SmallModal
        :open="showWelcome"
        title="Notice"
        noText="Dismiss"
        :yesButton="false"
        @close="showWelcome = false"
      >{{ $store.state.space.space.space.welcome_message }}</SmallModal>

      <SmallModal
        :open="inviteShow == true"
        title="Invite People"
        yesText="Copy Link"
        noText="Close"
        @close="inviteShow = false"
        @yes="
        copyLink();
        inviteShow = false;
        "
      >
        <div class="field">
          <div class="label">Share This Link!</div>
          <input
            type="text"
            data-lpignore="true"
            v-model="link"
            class="copy"
            @click="$refs.copyLink.select()"
            ref="copyLink"
          />
        </div>
      </SmallModal>

      <SmallModal
        :open="newRoomOpen"
        title="New Room"
        yesText="Create"
        noText="Cancel"
        @yes="newRoom"
        @close="newRoomOpen = false"
      >
        <div class="new-space">
          <div class="field">
            <div class="label">Name your Room</div>
            <input type="text" data-lpignore="true" v-model="newRoomName" @keydown.enter="newRoom" />
          </div>
          <div class="error" v-if="newRoomError != ''">{{ newRoomError }}</div>
        </div>
      </SmallModal>

      <SmallModal
        :open="newBoothOpen"
        title="New Table"
        yesText="Create"
        noText="Cancel"
        @yes="newBooth"
        @close="newBoothOpen = false"
      >
        <div class="new-space">
          <div class="field">
            <div class="label">Name your Table</div>
            <input
              type="text"
              ref="namebox"
              data-lpignore="true"
              v-model="newBoothName"
              @keydown.enter="newBooth"
            />
          </div>
          <div class="error" v-if="newBoothError != ''">{{ newBoothError }}</div>
        </div>
      </SmallModal>

      <SmallModal
        :open="newLinkOpen"
        title="New Link"
        yesText="Create"
        noText="Cancel"
        @yes="newLink"
        @close="newLinkOpen = false"
      >
        <div class="new-space">
          <div class="field">
            <div class="label">Title</div>
            <input type="text" ref="namebox" data-lpignore="true" v-model="newLinkName" />
          </div>
          <div class="field">
            <div class="label">Link</div>
            <input
              type="text"
              ref="namebox"
              data-lpignore="true"
              v-model="newLinkLink"
              @keydown.enter="newLink"
            />
          </div>
          <div class="error" v-if="newLinkError != ''">{{ newLinkError }}</div>
        </div>
      </SmallModal>

      <SmallModal
        :open="addQueueOpen"
        title="Add Myself To Queue"
        yesText="Add Me"
        noText="Cancel"
        @yes="addMeQueue"
        @close="
        addQueueOpen = false;
        subtext = '';
        addQueueError = '';
        "
      >
        <div class="new-space">
          Please describe what you need help with, please note down the problem
          and the part you're working on.
          <br />
          <br />
          <div class="field">
            <div class="label">What do you need help with?</div>
            <input
              type="text"
              ref="namebox"
              data-lpignore="true"
              v-model="subtext"
              @keydown.enter="addMeQueue"
            />
          </div>
          <div class="error" v-if="addQueueError != ''">{{ addQueueError }}</div>
        </div>
      </SmallModal>

      <ContextMenu ref="newTabMenu">
        <template slot-scope="{ meta }">
          <div @click="newTab('whiteboard', meta)">
            <span>Whiteboard</span>
          </div>
          <div @click="newTab('code', meta)">
            <span>Code</span>
          </div>
        </template>
      </ContextMenu>

      <ContextMenu ref="sortByMenu">
        <template>
          <div @click="sortBoothsBy = 'Default'">
            <i v-if="sortBoothsBy == 'Default'">
              <font-awesome-icon icon="check"></font-awesome-icon>
            </i>
            <span>Default</span>
          </div>
          <div @click="sortBoothsBy = 'Timing'">
            <i v-if="sortBoothsBy == 'Timing'">
              <font-awesome-icon icon="check"></font-awesome-icon>
            </i>
            <span>Timing</span>
          </div>
        </template>
      </ContextMenu>
    </Header>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import BigButton from "@/components/BigButton.vue";
import UserBubble from "@/components/UserBubble.vue";
import SmallModal from "@/components/SmallModal.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import Spinner from "@/components/Spinner.vue";
import Booth from "@/components/Booth.vue";
import LinkS from "@/components/LinkS.vue";
import VidChat from "@/components/VidChat.vue";

import notifSoundURL from "@/assets/notification.ogg";

import { Splitpanes, Pane } from "splitpanes";
// import "splitpanes/dist/splitpanes.css";

import { API } from "../api";
import Fuse from "fuse.js";
import moment from "moment-timezone";
import copy from "copy-to-clipboard";
import { Howl } from "howler";

import io from "socket.io-client";

let socket = undefined;

const notifSound = new Howl({
  src: [notifSoundURL]
});

function sortBoothsByTiming(booths) {
  booths.forEach(booth => {
    if (booth.hand_raised !== null) booth.mm = moment(booth.hand_raised);
    else booth.mm = null;
  });

  const nulls = booths.filter(b => b.mm === null);
  const valid = booths.filter(b => b.mm !== null);

  const validSorted = [...valid].sort((a, b) => a.mm - b.mm);
  return validSorted.concat(nulls);
}

export default {
  name: "Space",
  components: {
    Header,
    BigButton,
    SmallModal,
    Booth,
    LinkS,
    VidChat,
    Splitpanes,
    Pane,
    UserBubble,
    Spinner,
    ContextMenu
  },
  data() {
    return {
      pollInterval: undefined,
      newBoothOpen: false,
      newBoothName: "",
      newBoothError: "",
      newLinkOpen: false,
      newLinkName: "",
      newLinkLink: "",
      newLinkError: "",
      newRoomOpen: false,
      newRoomName: "",
      newRoomError: "",
      paneSize: "",
      showClear: false,
      filterMembersQuery: "",
      filterTable: "",
      queueShowing: false,
      error: "",
      addQueueOpen: false,
      subtext: "",
      addQueueError: "",
      currentTab: -1,
      sortBoothsBy: "Default",
      now: Date.now(),
      nowInterval: undefined,
      inviteShow: false,
      sounds: false,
      showWelcome: false,
    };
  },
  watch: {
    $route() { },
    uuid() {
      this.currentTab = -1;
    },
    "$store.state.space.space.tabs": function () {
      if (this.$store.state.space.space.tabs === undefined) return;

      const exists = this.$store.state.space.space.tabs.some(
        t => t.id === this.currentTab
      );
      if (!exists) {
        this.currentTab = -1;
      }
    },
    sounds() {
      localStorage.setItem("sounds", this.sounds);
    },
  },
  mounted() {
    if (this.$store.state.space.space.space.welcome_message !== "" && this.$store.state.space.space.space.welcome_message !== undefined && this.$store.state.space.space.space.welcome_message !== null) {
      this.showWelcome = true;
    }
  },
  created() {
    this.pollInterval = setInterval(() => {
      if (socket && socket.connected) {
        socket.emit("hb", this.$route.params.code);
      }
    }, 10000);

    this.nowInterval = setInterval(() => {
      this.now = Date.now();
    }, 1000);

    socket = io({
      transports: ["websocket"],
      path: "/socket"
    });

    socket.on("connect", () => {
      socket.emit("join", { room: this.$route.params.code });
    });

    socket.on("state", data => {
      if (data.booths != null) {
        data.booths.forEach(booth => {
          const filtered = this.$store.state.space.space.booths.filter(
            x => x.id === booth.id
          );
          if (filtered.length !== 0) {
            const previous = filtered[0];
            if (
              previous.hand_raised !== booth.hand_raised &&
              booth.hand_raised !== null
            ) {
              if (this.sounds) {
                notifSound.play();
              }
            }
          }
        });
      }

      this.$store.commit("space/setStateSocket", data);
    });

    socket.on("user_join", user => {
      this.$store.commit("space/addOnlineUser", user);
    });

    if (localStorage.getItem("paneSize") == undefined) {
      localStorage.setItem("paneSize", "25");
    }

    if (localStorage.getItem("sounds") == undefined) {
      localStorage.setItem("sounds", this.sounds);
    }

    this.paneSize = localStorage.getItem("paneSize");
    this.sounds = JSON.parse(localStorage.getItem("sounds"));
    window.moment = moment;
  },
  beforeDestroy() {
    if (this.pollInterval) clearInterval(this.pollInterval);
    if (this.nowInterval) clearInterval(this.nowInterval);
  },
  computed: {
    link() {
      return window.location.href;
    },
    tables() {
      const booths = this.$store.state.space.space.booths;
      if (this.filterTable == "") {
        if (this.sortBoothsBy === "Timing") return sortBoothsByTiming(booths);
        return booths;
      }

      const options = {
        keys: ["name", "users.display_name"]
      };

      const fuse = new Fuse(booths, options);
      const rv = fuse.search(this.filterTable).map(x => x.item);
      if (this.sortBoothsBy === "Timing") return sortBoothsByTiming(rv);
      return rv;
    },
    onlineRoles() {
      const options = {
        keys: ["display_name", "role.name"]
      };

      const fuse = new Fuse(this.$store.state.space.space.online, options);
      const online =
        this.filterMembersQuery != ""
          ? fuse.search(this.filterMembersQuery).map(x => x.item)
          : this.$store.state.space.space.online;

      return this.$store.state.space.space.roles
        .map(role => {
          return {
            role,
            members: online.filter(m => m.roles.indexOf(role) >= 0)
          };
        })
        .filter(r => r.members.length != 0);
    }
  },
  methods: {
    copyLink() {
      copy(window.location.href);
    },
    newTab(name) {
      API.new_tab(
        this.$route.params.code,
        this.$store.state.space.space.booth.id,
        name
      )
        .then(this.reload)
        .catch(this.showError);
    },
    queueMembers() {
      const members = this.$store.state.space.space.queue.members;
      members.forEach(member => {
        member.timing = moment(member.timestamp).fromNow();
      });
      return members;
    },
    reload() {
      // this.$store.dispatch("space/loadState", {
      //   code: this.$route.params.code,
      // });
    },
    reloadRest() {
      this.$store.dispatch("space/loadState", {
        code: this.$route.params.code
      });
    },
    joinRoom(room) {
      API.room_join(this.$route.params.code, room.id).then(this.reload);
    },
    leaveRoom() {
      API.room_leave(this.$route.params.code).then(this.reload);
    },
    deleteInactive() {
      API.delete_all_booth(
        this.$route.params.code,
        this.$store.state.space.space.room.id
      )
        .then(() => {
          this.reload();
        })
        .catch(e => {
          this.$toasted.show(e, {
            position: "bottom-center",
            duration: 3000,
            theme: "bubble",
            action: {
              text: "Dismiss",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          });
        });
    },
    newBooth() {
      this.newBoothError = "";
      API.new_booth(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.newBoothName
      )
        .then(() => {
          this.newBoothName = "";
          this.newBoothOpen = false;
          this.reload();
        })
        .catch(e => {
          this.newBoothError = e;
        });
    },
    newLink() {
      this.newLinkError = "";
      API.new_link(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.newLinkName,
        this.newLinkLink
      )
        .then(() => {
          this.newLinkName = "";
          this.newLinkLink = "";
          this.newLinkOpen = false;
          this.reload();
        })
        .catch(e => {
          this.newLinkError = e;
        });
    },
    newRoom() {
      this.newRoomError = "";
      API.new_room(this.$route.params.code, this.newRoomName)
        .then(() => {
          this.newRoomName = "";
          this.newRoomOpen = false;
          this.reload();
        })
        .catch(e => {
          this.newRoomError = e;
        });
    },
    leave() {
      API.leave_booth(this.$route.params.code).then(() => {
        this.reload();
      });
    },
    paneResize(size) {
      localStorage.setItem("paneSize", size);
    },
    toggleLocked() {
      API.lock_unlock_queue(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        !this.$store.state.space.space.queue.locked
      )
        .then(this.reload)
        .catch(this.showError);
    },
    addMeQueue() {
      API.add_myself_to_queue(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.subtext
      )
        .then(() => {
          this.subtext = "";
          this.addQueueOpen = false;
          this.reload();
        })
        .catch(e => {
          this.addQueueError = e;
        });
    },
    removeFromQueue(member) {
      API.delete_from_queue(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        member.user.id
      )
        .then(this.reload)
        .error(this.showError);
    },
    deleteTab(tab) {
      API.delete_tab(
        this.$route.params.code,
        this.$store.state.space.space.booth.id,
        tab.id
      )
        .then(() => {
          this.reload();
          this.currentTab = -1;
        })
        .catch(this.showError);
    },
    claimMember(member) {
      API.claim_on_queue(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        member.user.id
      )
        .then(this.reload)
        .error(this.showError);
    },
    unclaimMember(member) {
      API.unclaim_on_queue(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        member.user.id
      )
        .then(this.reload)
        .error(this.showError);
    },
    clearQueue() {
      API.clear_queue(
        this.$route.params.code,
        this.$store.state.space.space.room.id
      )
        .then(this.reload)
        .error(this.showError);
    },
    removeMeQueue() {
      API.remove_me_from_queue(
        this.$route.params.code,
        this.$store.state.space.space.room.id
      )
        .then(this.reload)
        .catch(this.showError);
    },
    showError(e) {
      this.error = e;
    }
  }
};
</script>

<style lang="scss" scoped>
$darkBG: rgb(25, 21, 32);
$bg: rgb(42, 35, 56);
$white: rgb(194, 194, 194);

.sound-control-wrapper > svg {
  color: rgb(84, 73, 107);
  cursor: pointer;
}

.sound-control-wrapper > svg:hover {
  color: rgb(101, 90, 124);
}

.sound-control-wrapper {
  margin-left: 1rem;
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.tab-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

.tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tab svg {
  padding: 0.5rem;
  font-size: 12px;
  color: rgb(145, 145, 145);
}

.tab-content.selected {
  opacity: 1;
  pointer-events: all;
}

.tab-content.framey iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
}

.space {
  background: $bg;
  display: grid;
  grid-template-columns: 1fr 15rem;
  grid-template-rows: minmax(0, 1fr);
  // grid-templayyte-columns: 1fr 4fr;
  // grid-template-rows: 10rem 3rem auto;
  width: 100%;
  height: 100%;

  // grid-template-areas:
  //   "buttons jitsi"
  //   "alltables jitsi"
  //   "booths jitsi";
}

.members {
  background: rgb(30, 28, 32);
  color: #919191;
  padding: 0.8rem 1rem;
  overflow-y: scroll;
}

.role-section {
  margin-bottom: 2rem;
}

.role-header {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.search-box-text {
  font-family: "Baloo Chettan 2";
  outline: none;
  border: 1px solid rgb(29, 29, 29);
  border-radius: 5px;
  background: #1d2630;
  padding: 0.8rem 0.8rem;
  font-size: 15px;
  resize: none;
  transition: 0.4s;
  width: 100%;
  box-sizing: border-box;
  transition: 0.1s ease border;
  color: rgb(172, 172, 172);
}

.search-box-text::placeholder {
  color: rgb(92, 92, 92);
}

.search-box-text:hover {
  border: 1px solid rgb(0, 0, 0);
}

.search-box-text:focus {
  border: 1px solid rgb(84, 117, 190);
}

.table-search {
  margin-top: 2rem;
}

.clear-search {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  margin-top: 2rem;
}

.left-pane {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem 2rem auto;
  height: 100%;
  grid-template-areas:
    "buttons"
    "sortby"
    "booths";
}

.right-pane {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  grid-template-areas: "jitsi";
}

.no-booths {
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
}

.new-room-plus {
  margin-bottom: 0.5rem;
}

.tab-bar {
  display: flex;
  flex-direction: row;
  color: $white;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.5rem;
  border-bottom: 1px solid #3c3b3f;
}

.tab {
  margin-left: 0.5rem;
  cursor: pointer;
  background: rgb(10, 10, 20);
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
  border-radius: 10px 10px 0px 0px;
}

.tab.first {
  padding-right: 1rem;
}

.tab.selected {
  background: rgb(60, 60, 136);
}

.new-tab {
  margin-left: 1rem;
  cursor: pointer;
  font-weight: bold;
}

.sort-by {
  text-align: center;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $darkBG;
}

.sort-by-prompt {
  color: grey;
  text-transform: uppercase;
  font-size: 13px;
}

.sort-by-text {
  padding: 0 0.5rem;
  font-size: 16px;
  border: 1px solid rgb(75, 75, 75);
  border-radius: 5px;
  margin-left: 0.5rem;
  cursor: pointer;
  user-select: none;
}

.sort-by-text svg {
  margin-left: 0.2rem;
}

.rooms {
  background: $bg;
  height: 100%;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.rooms-body {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 19rem);
  width: 100%;
  padding: 0 10rem;
  box-sizing: border-box;
  grid-row-gap: 2rem;
  justify-items: center;
  margin-bottom: 5rem;
}

.room-title2 {
  margin-top: 2rem;
  font-family: $headingFont;
  font-size: $headingFontSize;
}

.room {
  background: $darkBG;
  width: 17rem;
  height: 10rem;
  border-radius: 10px;
  border: 3px solid rgb(7, 6, 8);
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alltables {
  grid-area: alltables;
  background: $darkBG;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-room {
  background: rgb(39, 38, 65);
}

.room:hover {
  background: rgb(34, 29, 44);
}

.queue-entry-controls {
  font-size: 15px;
}

.queue-entry-controls > div {
  margin-left: 0.5rem;
}

.queue-table {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.queue-table table {
  border-collapse: collapse;
  width: 100%;
}

.queue-table thead {
  font-weight: bold;
}

.queue-close {
  position: absolute;
  padding: 0.5rem 1.5rem;
  color: rgb(153, 175, 156);
  font-family: "Baloo Chettan 2";
  font-size: 30px;
  cursor: pointer;
  border-radius: 20px;
  left: 0;
  top: 0;
}

.queue-close:hover {
  background: rgb(65, 65, 65);
}

.butts {
  grid-area: buttons;
  background: $darkBG;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toolbar {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.queue-bar {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.join {
  margin-top: 1rem;
  font-size: 15px;
}

.jitsi {
  // grid-area: jitsi;
  width: 100%;
  height: 100%;
}

.tabs-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  margin-top: 0.5rem;
}

.tab-contents {
  position: relative;
  width: 100%;
  height: 100%;
}

.strike {
  text-decoration: line-through;
}

.queue-wrapper {
  position: absolute;
  background: rgb(30, 28, 32);
  left: 3rem;
  right: 3rem;
  top: 3rem;
  bottom: 3rem;
  border-radius: 20px;
  color: $white;
  padding: 2rem 4rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border: solid 1px #000000;
  overflow-y: scroll;
  z-index: 9999;
}

.queue-title {
  font-family: "Playfair Display";
  font-size: 30px;
  text-align: center;
}

.copy {
  cursor: pointer;
}

.mid-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  grid-area: jitsi;
}

.timing {
  font-size: 17px;
}

.booths {
  grid-area: booths;
  background: $darkBG;
  overflow-y: auto;
}

.booth {
  margin-bottom: 2rem;
}

.error {
  color: red;
}

.booths-wrapper {
  // position: relative;
  margin-top: 1rem;
}

.room-title {
  color: $white;
  font-family: "Source Sans Pro";
  margin-bottom: 1.5rem;
}

.room-is {
  color: grey;
  font-size: 13px;
}

.room-name {
  font-weight: 600;
  font-size: 25px;
}

.settings2 {
  color: grey;
  font-size: 14px;
  margin-left: 0.1rem;
  cursor: pointer;
}

.splitpanes--dragging .jitsi:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.link {
  margin-bottom: 2rem;
}

.ifame {
  width: 0;
  height: 0;
  border: 0;
  border: none;
  display: none;
}

.queue-controls {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 15px;
}

.queue-controls > div {
  margin-right: 0.5rem;
}

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 8px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: rgba(57, 49, 83, 0.5);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(57, 52, 99, 0.61); /* Some darker color when you click it */
  border-radius: 100px;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
</style>
