<template>
  <div id="header">
    <div
      class="header"
      :class="{ headerWhite: !dark, headerDark: dark, splash2: inSplash }"
    >
      <div class="back-button" @click="back">
        <transition name="fade">
          <img
            v-if="backButton"
            class="bbimg"
            :class="{ invert: dark }"
            src="@/assets/back.svg"
          />
        </transition>
        <img
          class="bbimg"
          src="@/assets/bonfire.svg"
          v-if="inSpace && !backButton"
        />
      </div>
      <div class="logo-text" :class="{ splash2: inSplash }">
        <span @click="back" class="text">
          <img class="logo-image" src="@/assets/bonfire.svg" v-if="!inSpace" />
          {{ appText }}
        </span>
        <router-link
          :to="{ name: 'spacesettings', params: { code: $route.params.code } }"
          v-if="$store.state.space.space.admin"
        >
          <span
            class="settings"
            v-if="noHome"
            v-tooltip="'Edit Space Settings'"
          >
            <font-awesome-icon icon="pen"></font-awesome-icon>
          </span>
        </router-link>
      </div>
      <div
        class="profile-button"
        @click="isOpen = true"
        v-if="$store.state.user.loggedIn && !inSplash"
      >
        <img src="@/assets/user.svg" v-if="!dark" />
        <img src="@/assets/user-black.svg" v-else />
      </div>
      <transition name="fade">
        <div class="profile-button" v-if="inSplash && scrollTop >= 600">
          <router-link to="/login">
            <BigButton color="black">Create A Space</BigButton>
          </router-link>
        </div>
      </transition>
    </div>
    <div :class="{ slot: noflow }" class="header-slot">
      <slot></slot>
    </div>

    <transition name="bounce">
      <UserDropdown v-if="isOpen" @clickabroad="ca"></UserDropdown>
    </transition>
  </div>
</template>

<script>
import UserDropdown from "@/components/UserDropdown.vue";
import BigButton from "@/components/BigButton.vue";

export default {
  name: "Header",
  components: { UserDropdown, BigButton },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    noflow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      scrollTop: 0,
    };
  },
  created() {
    document.addEventListener("scroll", this.scroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.scroll);
  },
  computed: {
    appText() {
      if (this.$router.currentRoute.name == "space") {
        return this.$store.state.space.space.name;
      } else {
        return "FireMeet";
      }
    },
    inSpace() {
      return this.$route.name == "space";
    },
    inSplash() {
      return this.$route.name == "splash";
    },
    backButton() {
      const route = this.$router.currentRoute.name;

      return (
        route != "space" &&
        route != "spaces" &&
        route != "splash" &&
        route != "spacelogin" &&
        !(
          this.$store.state.space.space.user &&
          this.$store.state.space.space.user.wizard &&
          route == "profile"
        )
      );

      // return (
      //   (this.$store.state.user.loggedIn &&
      //     !this.$store.state.user.me.wizard &&
      //      != "events") ||
      //   (!this.$store.state.user.loggedIn &&
      //     this.$router.currentRoute.name != "splash") ||
      //   this.$router.currentRoute.name != "space"
      // );
    },
    noHome() {
      return this.$router.currentRoute.name == "space";
    },
  },
  methods: {
    ca() {
      if (this.isOpen) this.isOpen = false;
    },
    back() {
      if (this.$route.params.code) {
        this.$router.push("/space/" + this.$route.params.code);
      } else {
        this.$router.push("/spaces");
      }
    },
    scroll() {
      this.scrollTop = window.scrollY;
    },
  },
};
</script>

<style lang="scss">
.slot {
  overflow: hidden;
  height: calc(100vh - 4rem);
}

#header {
  width: 100%;
  height: 100vh;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 4rem auto;
}

.logo-image {
  width: 1.5rem;
  margin-right: 0.5rem;
}

.header-slot {
  margin-top: 4rem;
}

.text {
  display: flex;
  align-items: center;
}

.header {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 4rem auto 4rem;

  // position: sticky;
  top: 0;
  z-index: 1;

  position: fixed;
  width: 100%;
  height: 4rem;

  grid-template-areas: "backButton logoText profileButton";
}

.header.splash2 {
  grid-template-columns: 12rem auto 12rem;
  grid-template-areas: "backButton logoText profileButton";
}

@media (max-width: $breakpoint-mobile) {
  .header.splash2 {
    grid-template-columns: 0rem auto 12rem;
    grid-template-areas: "backButton logoText profileButton";
  }
}

// .header2 {
//   display: grid;
//   grid-template-rows: 100%;
//   grid-template-columns: 20rem auto 20rem;

//   position: sticky;
//   top: 0;
//   z-index: 1;

//   grid-template-areas: "logoText backButton profileButton";
// }

.headerWhite {
  background: #ffffffa1;
  border-bottom: 1px black solid;
}

.headerDark {
  background: black;
  color: white;
}

.logo-text {
  font-family: $logoFont;
  font-size: $logoFontSize;
  font-weight: 600;
  text-align: center;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: logoText;
  display: flex;
  align-items: center;
}

@media (max-width: $breakpoint-mobile) {
  .logo-text.splash2 {
    justify-content: flex-start;
    margin-left: 2rem;
  }
}

.profile-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  grid-area: profileButton;
}

.profile-button > img {
  width: 2.5rem;
  height: 2.5rem;
}

.back-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  grid-area: backButton;
}

.back-button-border {
  border-right: 1px solid black;
}

.black-logo {
  width: 2rem;
}

.bbimg {
  width: 1.5rem;
  height: 1.5rem;
}

.back-button:hover > img {
  animation: backflip 0.5s;
}

@keyframes backflip {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bounce-enter-active {
  transform-origin: top center;
  animation: bounce-in 0.12s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.invert {
  filter: invert(1);
}

.text {
  cursor: pointer;
}

.settings {
  color: rgb(194, 194, 194);
  font-size: 20px;
  margin-left: 1rem;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
