<template>
  <div class="link">
    <div class="name">
      <div class="name-text">
        <span class="hash">
          <font-awesome-icon icon="link"></font-awesome-icon>
        </span>
        {{ link.name }}
        <div class="cog">
          <!-- <font-awesome-icon
          icon="pen"
          v-tooltip.top="'Rename'"
          @click="renameOpen = true; renameName = link.name"
          ></font-awesome-icon>-->
          <font-awesome-icon icon="trash" v-tooltip.top="'Delete'" @click="deleteOpen = true"></font-awesome-icon>
        </div>
      </div>
      <div class="show-buttons">
        <a target="_blank" rel="noreferrer" :href="link.link">
          <div class="small-button">
            <span>New Tab</span>
            <font-awesome-icon class="external" icon="external-link-alt"></font-awesome-icon>
          </div>
        </a>
        <div class="small-button" @click="showing = true">Show</div>
      </div>
    </div>

    <div class="show" v-if="false">
      <BigButton color="blue" @click="showing = true" v-if="!showing">Show</BigButton>
      <BigButton color="dark-pink" @click="showing = false" v-else>Hide</BigButton>
    </div>

    <SmallModal
      :open="renameOpen"
      title="Rename Link"
      yesText="Rename"
      noText="Cancel"
      @yes="rename"
      @close="renameOpen = false; reload()"
    >
      <div class="rename">
        <div class="field">
          <div class="label">Link Title</div>
          <input type="text" data-lpignore="true" v-model="renameName" @keydown.enter="rename" />
        </div>
        <div class="error" v-if="renameError != ''">{{ renameError }}</div>
      </div>
    </SmallModal>

    <SmallModal
      :open="deleteOpen"
      title="Are you sure?"
      yesText="Delete"
      noText="Cancel"
      @yes="deleteOpen = false; deleteMe();"
      @close="deleteOpen = false;"
    >You can't undo this.</SmallModal>

    <LinkFrame :open="showing" @close="showing = false" :link="link"></LinkFrame>
  </div>
</template>
<script>
// import ProfilePicture from "@/components/ProfilePicture.vue";
import SmallModal from "@/components/SmallModal.vue";
import LinkFrame from "@/components/LinkFrame.vue";
import BigButton from "@/components/BigButton.vue";
import { API } from "../api";
// import { wrapGrid } from "animate-css-grid";

export default {
  name: "Link",
  components: { SmallModal, BigButton, LinkFrame },
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showing: false,
      dragging: false,
      dropping: false,
      enterTarget: 0,
      error: "",
      renameOpen: false,
      renameError: "",
      renameName: "",
      deleteOpen: false
    };
  },
  methods: {
    reload() {
      this.$store.dispatch("space/loadState", {
        code: this.$route.params.code
      });
    },
    rename() {
      API.rename_booth(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.booth.id,
        this.renameName
      )
        .then(() => {
          this.renameOpen = false;
          this.reload();
        })
        .catch(e => {
          this.renameError = e;
        });
    },
    deleteMe() {
      API.delete_link(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.link.id
      )
        .then(this.reload)
        .catch(this.toastError);
    },
    toastError(e) {
      this.$toasted.show(e, {
        position: "bottom-center",
        duration: 3000,
        theme: "bubble",
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.link {
  user-select: none;
}

a {
  color: #c2c2c2;
}

.locked {
  background-image: linear-gradient(
    45deg,
    #ee617d5e 25%,
    transparent 25%,
    transparent 50%,
    #ee617d5e 50%,
    #ee617d5e 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 40px 40px;
}

.name {
  background: #2d2b38;
  color: rgb(194, 194, 194);
  padding: 0.5rem 1.5rem;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.cog {
  opacity: 0;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.5rem;
  display: inline;
}

.cog svg:hover {
  color: rgb(209, 209, 209);
}

.show-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-button {
  border-radius: 10px;
  padding: 0 0.7rem;
  background: #564e65;
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-button:hover {
  background: #655b79;
}

.small-button .external {
  font-size: 10px;
}

.name:hover .cog {
  opacity: 1;
}

.hash {
  font-weight: lighter;
  color: rgb(77, 77, 77);
  margin-right: 0.3rem;
}

.show {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}
</style>