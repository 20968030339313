<template>
  <div class="legal md" v-html="dateInjected"></div>
</template>
<script>
function getDate() {
  const date = new Date();
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();

  return `<u>${month} ${day}, ${year}</u>`;
}

export default {
  name: "Legal",
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  computed: {
    dateInjected() {
      return this.source.replace("{{ date }}", getDate());
    },
  },
};
</script>
<style lang="scss">
.md h1 {
  font-family: "Playfair Display";
  font-weight: normal;
}

.md h2 {
  font-family: "Playfair Display";
  font-weight: normal;
}

.md p {
  color: rgb(70, 70, 78);
}

.md a:hover {
  background: blue;
  color: white;
}

.md {
  margin-left: 3rem;
  margin-top: 2rem;
  margin-right: 3rem;
  max-width: 60rem;
  margin-bottom: 20rem;
}
</style>