import Vue from 'vue'
import axios from 'axios'

import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted';
import VTooltip from 'v-tooltip'
import VueScrollTo from 'vue-scrollto';


import './icons'

Vue.use(Toasted);
Vue.use(VTooltip);
Vue.use(VueScrollTo);

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')