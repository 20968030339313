<template>
  <div>
    <Header>
      <div class="login">
        <div class="login-header">Sign In</div>
        <LoginStack :space="false"></LoginStack>
        <!-- <div class="currently-at">Free for spaces &le; 50 people.</div> -->
      </div>
    </Header>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import LoginStack from "@/components/LoginStack.vue";

export default {
  name: "Login",
  components: { Header, LoginStack },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.login {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-header {
  font-family: $headingFont;
  font-size: $headingFontSize;
  // animation: fadeInUp 0.5s ease 0s 1 forwards;
}

.login-hint {
  margin-top: 3rem;
  color: black;
}

.login-box {
  margin-top: 1rem;
  width: 20rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.login-box input {
  outline: none;
  text-align: left;
  color: #35495e;
  padding: 0.8rem 1.2rem;
  border-radius: 5px;
  border: 1px solid #969696;
  background: #fff;
  font-size: 15px;
  transition: 0.4s;
  width: 13rem;
}

.login-box input:hover {
  border: 1px solid rgb(68, 68, 68);
}

.login-box input:focus {
  border: 1px solid rgb(109, 68, 255);
}

.login-box input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(199, 199, 199);
  opacity: 1; /* Firefox */
}

$orColor: rgb(116, 116, 116);

.login-or {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: $orColor;
}

.login-line {
  width: 5rem;
  height: 0;
  border-top: 1px solid $orColor;
  margin: 0rem 0.5rem;
}

.touchstone {
  margin-top: 1.5rem;
}

.currently-at {
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 0rem 3rem;
  text-align: center;
  color: #525252;
  max-width: 30rem;
  width: 100%;
  box-sizing: border-box;
}

.college-list {
  margin-top: 1rem;
  text-align: center;
  color: black;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.will-login {
  margin-top: 2rem;
}

.policy-blurb {
  width: 15rem;
  font-size: 12px;
  margin-top: 2rem;
  text-align: center;
  color: darkgrey;
}

.policy-blurb a {
  text-decoration: underline;
  color: #9292cc;
}

.policy-blurb a:hover {
  color: white;
  background: #9292cc;
}

.social-icon {
  margin-right: 0.5rem;
  font-size: 0.8em;
}
</style>
