<template>
  <div id="splash">
    <Header>
      <div class="big-text">404: Page not found :/</div>
    </Header>
  </div>
</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";

export default {
  name: "PageNotFound",
  components: {
    Header
  }
};
</script>

<style lang="scss">
.big-text {
  text-align: center;
  padding: 3rem;
  font-family: "Playfair Display";
  font-size: 35px;
}
</style>
