import { render, staticRenderFns } from "./VidChat.vue?vue&type=template&id=a79616e6&scoped=true&"
import script from "./VidChat.vue?vue&type=script&lang=js&"
export * from "./VidChat.vue?vue&type=script&lang=js&"
import style0 from "./VidChat.vue?vue&type=style&index=0&id=a79616e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a79616e6",
  null
  
)

export default component.exports