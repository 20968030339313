<template>
  <transition name="bounce2">
    <div class="small-modal" v-if="open">
      <div class="small-modal-inner" v-if="open" v-on-clickaway="ca">
        <!-- <div class="small-modal-title">{{ link.name }}</div> -->
        <div class="small-modal-content">
          <Spinner radius="40px" v-if="!loaded" theme="dark"></Spinner>
          <iframe :src="link.link" frameborder="0" @load="loaded=true"></iframe>
        </div>
        <div class="close" @click="$emit('close')">x</div>
      </div>
    </div>
  </transition>
</template>
<script>
// import BigButton from "@/components/BigButton.vue";
import Spinner from "@/components/Spinner.vue";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "LinkFrame",
  mixins: [clickaway],
  components: { Spinner },
  props: {
    link: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    },
    shadowClose: {
      type: Boolean,
      default: true
    },
    noText: {
      default: "Close"
    },
    noButton: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  methods: {
    ca() {
      if (this.shadowClose) this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped>
.small-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: #0000005e;
}

.small-modal-inner {
  padding: 1rem 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 14px 14px 7px #00000029;
  display: grid;
  grid-template-rows: 2rem auto;
  grid-template-columns: auto 2rem;
  grid-template-areas:
    "iframe close"
    "iframe .";
}

.close {
  grid-area: close;
  font-family: "Baloo Chettan 2";
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  font-size: 20px;
  border-radius: 100%;
}

.close:hover {
  background: rgb(204, 204, 204);
}

.small-modal-title {
  text-align: left;
  font-family: "Playfair Display";
  font-size: 24px;
}

.small-modal-content {
  font-size: 15px;
  position: relative;
  grid-area: iframe;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-modal-content iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.small-modal-buttons {
  text-align: right;
}

.llb {
  margin-right: 0.5rem;
}

.bounce2-enter-active {
  transform-origin: center center;
  animation: nofade 0.2s;
}

.bounce2-enter-active > .small-modal-inner {
  animation: bounce2-in 0.2s;
}

@keyframes bounce2-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes nofade {
  0% {
    opacity: 0.99999;
  }
  100% {
    opacity: 1;
  }
}

.small-modal-inner {
  width: 80%;
  height: 80%;
}
</style>
