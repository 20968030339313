<template>
  <div class="big-button" @click="$emit('click', $event)" :class="classDict">
    <div class="button-grid-wrapper">
      <div class="button-slot-wrapper" :class="{ 'button-vanish': loading }">
        <slot></slot>
      </div>
      <div class="big-button-wrapper" :class="{ 'button-show': loading }">
        <Spinner theme="light"></Spinner>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";

export default {
  name: "BigButton",
  components: { Spinner },
  props: {
    color: {
      type: String,
      default: "white"
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    classDict() {
      let obj = {};
      obj[this.color] = true;
      obj.disabledbutton = this.disabled;
      return obj;
    }
  }
};
</script>

<style lang="scss">
.button-grid-wrapper {
  display: grid;
  grid-template-areas: "area";
}

.big-button {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 3px rgba(0, 0, 0, 0.1);

  display: inline-block;
}

.disabledbutton {
  cursor: not-allowed;
}

.big-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.big-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  grid-area: area;
  opacity: 0;
}

.big-button.black {
  color: white;
  background-color: black;
}

.big-button.white {
  color: black;
  background-color: white;
}

.big-button.pink {
  color: white;
  background-color: #ff7675;
}

.big-button.dark-pink {
  color: white;
  background-color: #ad5f5f;
}

.big-button.blue {
  color: white;
  background-color: #0984e3;
}

.big-button.red {
  color: white;
  background-color: #d63031;
}

.big-button.green {
  color: white;
  background-color: #328336;
}

.google {
  color: white;
  background-color: #4285f4;
}

.button-vanish {
  opacity: 0;
}

.button-show {
  opacity: 1;
}

.button-slot-wrapper {
  grid-area: area;
  text-align: center;
}
</style>