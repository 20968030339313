import axios from 'axios'
import moment from 'moment-timezone';

const state = {
    me: {},
    hour: {
        active: false,
    },
    loggedIn: false,
    loaded: false,
    uploading: false,
    schedule: [],
}

// getters
const getters = {
    userTimezone(state) {
        if (state.me.timezone) return state.me.timezone;
        return moment.tz.guess();
    }
}

// actions
const actions = {
    getMyself(context) {
        axios.get('/api/user').then((res) => {
            context.commit('setLoggedInStatus', true);
            context.commit('setMyself', res.data);
            context.commit('setLoaded');
        }).catch(() => {
            context.commit('setLoggedInStatus', false);
            context.commit('setMyself', {});
            context.commit('setLoaded');
        });
    },
    getSchedule(context) {
        axios.get('/api/schedule').then((res) => {
            context.commit('setSchedule', res.data);
        }).catch(() => {
            context.commit('setSchedule', []);
        });
    },
    setGenderAndInterests(context, {
        gender,
        interests,
        friendMode,
    }) {
        return new Promise((resolve, reject) => {
            axios.post('/api/user/gender', {
                gender: gender,
                interests: interests,
                friend_mode: friendMode,
            }).then((res) => {
                context.commit('setMyself', res.data);
                context.commit('setLoaded');
                resolve();
            }).catch((error) => {
                reject(error.response.data.error);
            });
        });
    },
    setSchools(context, {
        schools,
    }) {
        return new Promise((resolve, reject) => {
            axios.post('/api/user/schools', {
                schools: schools,
            }).then((res) => {
                context.commit('setMyself', res.data);
                context.commit('setLoaded');
                resolve();
            }).catch((error) => {
                reject(error.response.data.error);
            });
        });
    },
    setTimezone(context, timezone) {
        context.commit('uploading');
        axios.post('/api/user/timezone', {
            timezone: timezone
        }).then(
            (res) => {
                context.commit('finishUploading');
                context.commit('setMyself', res.data);
                context.commit('setLoaded');
            }).catch(() => {
            // TODO(revalo): Gracefully handle this error.
            context.commit('finishUploading');
            context.commit('setLoaded');
        });
    },
    setPaused(context, paused) {
        return new Promise((resolve, reject) => {
            context.commit('uploading');
            axios.post('/api/user/paused', {
                paused: paused,
            }).then((res) => {
                context.commit('finishUploading');
                context.commit('setMyself', res.data);
                context.commit('setLoaded');
                resolve();
            }).catch((error) => {
                reject(error.response.data.error);
            })
        });
    },
    setNotify(context, notify) {
        return new Promise((resolve, reject) => {
            context.commit('uploading');
            axios.post('/api/user/notify', {
                notify: notify,
            }).then((res) => {
                context.commit('finishUploading');
                context.commit('setMyself', res.data);
                context.commit('setLoaded');
                resolve();
            }).catch((error) => {
                reject(error.response.data.error);
            })
        });
    },
    setSchedule(context, schedule) {
        return new Promise((resolve, reject) => {
            context.commit('uploading');
            axios.post('/api/schedule', {
                schedule: schedule,
            }).then((res) => {
                context.commit('finishUploading');
                context.commit('setSchedule', res.data);
                resolve();
            }).catch((error) => {
                reject(error.response.data.error);
            })
        });
    },
    setGreeting(context, greeting) {
        return new Promise((resolve, reject) => {
            context.commit('uploading');
            axios.post('/api/user/greeting', {
                greeting: greeting,
            }).then((res) => {
                context.commit('finishUploading');
                context.commit('setMyself', res.data);
                context.commit('setLoaded');
                resolve();
            }).catch((error) => {
                reject(error.response.data.error);
            })
        });
    },
    getHour(context) {
        return new Promise((resolve, reject) => {
            axios.get('/api/hour').then((res) => {
                context.commit('setHour', res.data);
                resolve();
            }).catch(() => {
                reject();
            });
        });

    },
}

// mutations
const mutations = {
    setLoggedInStatus(state, status) {
        state.loggedIn = status;
    },
    setMyself(state, user) {
        state.me = user;
        if (state.me.interests == null) state.me.interests = [];
    },
    setHour(state, hour) {
        state.hour = hour;
    },
    setLoaded(state) {
        state.loaded = true;
    },
    setGender(state, gender) {
        state.user.gender = gender;
    },
    setInterests(state, interests) {
        state.user.interests = interests;
    },
    uploading(state) {
        state.uploading = true;
    },
    finishUploading(state) {
        state.uploading = false;
    },
    setSchedule(state, schedule) {
        state.schedule = schedule;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}