import axios from 'axios'

const state = {
    genderList: [],
    schools: [],
    loaded: false,
}

// getters
const getters = {}

// actions
const actions = {
    getConstants(context) {
        axios.get('/api/constants').then((res) => {
            context.commit('setGenderList', res.data.gender_list);
            context.commit('setSchools', res.data.schools);
            context.commit('setLoaded');
        }).catch(() => {
            context.commit('setLoaded');
        });
    }
}

// mutations
const mutations = {
    setGenderList(state, genderList) {
        state.genderList = genderList;
    },
    setSchools(state, schools) {
        state.schools = schools;
    },
    setLoaded(state) {
        state.loaded = true;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}