import Vue from 'vue'
import VueRouter from 'vue-router'
import Splash from '../views/Splash.vue'
// import Dashboard from '../views/Dashboard.vue'
import Events from '../views/Events.vue'
import Space from '../views/Space.vue'
import SpaceLogin from '../views/SpaceLogin.vue'
import Profile from '../views/Profile.vue'
// import Matches from '../views/Matches.vue'
// import Wizard from '../views/Wizard.vue'
// import Schools from '../views/Schools.vue'
// import Schedule from '../views/Schedule.vue'
// import Report from '../views/Report.vue'
// import Feedback from '../views/Feedback.vue'
// import Date from '../views/Date.vue'
// import Consent from '../views/Consent.vue'
import Login from '../views/Login.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Browser from '../views/Browser.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'

import SpaceSettings from '../components/settings/SpaceSettings.vue'
// import Consent from '../views/Consent.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'splash',
  component: Splash,
  beforeEnter: rootGuard
},
{
  path: '/spaces',
  name: 'spaces',
  component: Events,
  beforeEnter: requireAuth
},
{
  path: '/space/:code',
  name: 'space',
  component: Space,
  beforeEnter: spaceGuard,
},
{
  path: '/space/:code/settings',
  name: 'spacesettings',
  component: SpaceSettings,
  beforeEnter: spaceGuard,
},
{
  path: '/space/:code/login',
  name: 'spacelogin',
  component: SpaceLogin,
  beforeEnter: spaceLoginGuard,
},
{
  path: '/space/:code/profile',
  name: 'profile',
  component: Profile,
  beforeEnter: spaceProfileGuard,
},
{
  path: '/browser',
  name: 'browser',
  component: Browser,
},
{
  path: '/privacy',
  name: 'privacy',
  component: Privacy,
},
{
  path: '/terms',
  name: 'terms',
  component: Terms,
},
{
  path: '/login',
  name: 'login',
  component: Login,
  beforeEnter: rootGuard,
},
{
  path: '*',
  name: '404',
  component: PageNotFound,
  beforeEnter: loadUser
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

// function isSafari() {
//   var ua = navigator.userAgent.toLowerCase();
//   if (ua.indexOf('safari') != -1) {
//     if (ua.indexOf('chrome') > -1) {
//       return false;
//     } else {
//       return true;
//     }
//   }

//   return false;
// }

function loadUser(_to, _from, next) {
  if (store.state.user.loaded) {
    store.dispatch('user/getMyself');
    next();
  } else {
    store.dispatch('user/getMyself');
    store.watch(() => {
      return store.state.user.loaded;
    }, () => {
      next();
    });
  }
}

function requireAuth(_to, _from, next) {
  if (store.state.user.loaded) {
    store.dispatch('user/getMyself');
    if (store.state.user.loggedIn) next();
    else next('/');
  } else {
    store.dispatch('user/getMyself');
    store.watch(() => {
      return store.state.user.loaded;
    }, () => {
      if (store.state.user.loggedIn) next();
      else next('/');
    });
  }
}

function spaceProfileGuard(_to, _from, next) {
  if (store.state.user.loaded) {
    store.dispatch('user/getMyself');
    if (store.state.user.loggedIn) next();
    else next(`/space/${_to.params.code}/login`);
  } else {
    store.dispatch('user/getMyself');
    store.dispatch('space/loadState', {
      code: _to.params.code
    });
    store.watch(() => {
      return store.state.user.loaded;
    }, () => {
      if (store.state.user.loggedIn) next();
      else next(`/space/${_to.params.code}/login`);
    });
  }
}

function spaceGuard(_to, _from, next) {
  if (store.state.user.loaded) {
    store.dispatch('user/getMyself');
    if (store.state.user.loggedIn) {
      store.dispatch('space/loadState', {
        code: _to.params.code,
      });
      store.watch(() => {
        return store.state.space.loading;
      }, () => {
        if (store.state.space.space.user.wizard) next(`/space/${_to.params.code}/profile`);
        else next();
      });
    } else next(`/space/${_to.params.code}/login`);
  } else {
    store.dispatch('user/getMyself');
    store.watch(() => {
      return store.state.user.loaded;
    }, () => {
      if (store.state.user.loggedIn) {
        store.dispatch('space/loadState', {
          code: _to.params.code,
        });
        store.watch(() => {
          return store.state.space.loading;
        }, () => {
          if (store.state.space.space.user.wizard) next(`/space/${_to.params.code}/profile`);
          else next();
        });
      } else next(`/space/${_to.params.code}/login`);
    });
  }
}

function spaceLoginGuard(_to, _from, next) {
  if (store.state.user.loaded) {
    store.dispatch('user/getMyself');
    if (store.state.user.loggedIn) next(`/space/${_to.params.code}`);
    else next();
  } else {
    store.dispatch('user/getMyself');
    store.watch(() => {
      return store.state.user.loaded;
    }, () => {
      if (store.state.user.loggedIn) next(`/space/${_to.params.code}`);
      else next();
    });
  }
}

// function requireAuthAfterWizard(to, _from, next) {
//   if (store.state.user.loaded) {
//     store.dispatch('user/getMyself');
//     if (store.state.user.loggedIn) {
//       if (to.name == 'schedule') {
//         store.dispatch('user/getSchedule').then(() => {
//           wizardRedirect(next);
//         });
//       } else {
//         wizardRedirect(next);
//       }
//     } else next('/');
//   } else {
//     store.dispatch('user/getMyself');
//     store.watch(() => {
//       return store.state.user.loaded;
//     }, () => {
//       if (store.state.user.loggedIn) wizardRedirect(next);
//       else next('/');
//     });
//   }
// }

function rootGuard(_to, _from, next) {
  if (store.state.user.loaded) {
    store.dispatch('user/getMyself');
    if (store.state.user.loggedIn) {
      rootRedirect(next);
    } else next();
  } else {
    store.dispatch('user/getMyself');
    store.watch(() => {
      return store.state.user.loaded;
    }, () => {
      if (store.state.user.loggedIn) {
        rootRedirect(next);
      } else next();
    });
  }
}

function rootRedirect(next) {
  next('/spaces');
}

// function rootRedirect(next) {
//   if (store.state.user.me.wizard) next('/wizard');
//   else next('/dashboard');
// }

// function wizardRedirect(next) {
//   if (store.state.user.me.wizard) next('/wizard');
//   else next();
// }


// function requireAuthAfterWizardDash(to, _from, next) {
//   if (store.state.user.loaded) {
//     store.dispatch('user/getMyself');
//     if (store.state.user.loggedIn) {
//       store.dispatch('user/getHour').then(() => {
//         wizardRedirect(next);
//       });
//     } else next('/');
//   } else {
//     store.dispatch('user/getMyself');
//     store.watch(() => {
//       return store.state.user.loaded;
//     }, () => {
//       if (store.state.user.loggedIn) {
//         store.dispatch('user/getHour').then(() => {
//           wizardRedirect(next);
//         });
//       } else next('/');
//     });
//   }
// }

// function requireAuthAfterWizardDate(to, _from, next) {
//   if (isSafari()) {
//     next('/browser');
//     return;
//   }

//   if (store.state.user.loaded) {
//     store.dispatch('user/getMyself');
//     if (store.state.user.loggedIn) {
//       store.dispatch('user/getHour').then(() => {
//         if (!store.state.user.hour.active) {
//           next('/dashboard');
//           return;
//         }
//         wizardRedirect(next);
//       });
//     } else next('/');
//   } else {
//     store.dispatch('user/getMyself');
//     store.watch(() => {
//       return store.state.user.loaded;
//     }, () => {
//       if (store.state.user.loggedIn) {
//         store.dispatch('user/getHour').then(() => {
//           if (!store.state.user.hour.active) {
//             next('/dashboard');
//             return;
//           }
//           wizardRedirect(next);
//         });
//       } else next('/');
//     });
//   }
// }