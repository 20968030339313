<template>
  <div class="profile-picture">
    <img
      :src="user.profile_picture"
      draggable="false"
      v-if="user.profile_picture"
      :style="{ borderColor: borderColor }"
    />
    <div class="initials" v-else>
      <Initials :name="user.display_name" :style="{ borderColor: borderColor }"></Initials>
    </div>
  </div>
</template>
<script>
import Initials from "@/components/Initials.vue";

export default {
  name: "ProfilePicture",
  components: { Initials },
  props: {
    user: {
      type: Object,
      required: true
    },
    borderColor: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {}
};
</script>
<style lang="scss" scoped>
.profile-picture {
  width: 100%;
  height: 100%;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 2px solid rgb(139, 89, 255);
}

.initials {
  width: 100%;
  height: 100%;
}
</style>