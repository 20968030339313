var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"booth",class:{ locked: _vm.booth.locked },on:{"dragenter":function($event){$event.preventDefault();return _vm.dragEnter($event)},"dragover":function($event){$event.preventDefault();},"dragleave":function($event){$event.preventDefault();return _vm.dragLeave($event)},"drop":_vm.drop}},[_c('div',{staticClass:"name"},[_c('div',{staticClass:"name-text"},[_c('span',{staticClass:"hash"},[_vm._v("#")]),_vm._v(" "+_vm._s(_vm.booth.name)+" "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Request Help'),expression:"'Request Help'"}],staticClass:"raise-hand",class:{ active: _vm.booth.hand_raised !== null },on:{"click":_vm.raiseHand}},[_c('font-awesome-icon',{attrs:{"icon":"hand-paper"}})],1),(_vm.booth.hand_raised !== null)?_c('span',{staticClass:"timing"},[_vm._v(_vm._s(_vm.handRaisedTime))]):_vm._e()]),_c('div',{staticClass:"cog"},[(_vm.booth.locked)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Unlock this table'),expression:"'Unlock this table'",modifiers:{"top":true}}],attrs:{"icon":"lock"},on:{"click":_vm.lock}}):_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Lock this table'),expression:"'Lock this table'",modifiers:{"top":true}}],attrs:{"icon":"lock-open"},on:{"click":_vm.lock}}),_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Rename'),expression:"'Rename'",modifiers:{"top":true}}],attrs:{"icon":"pen"},on:{"click":function($event){_vm.renameOpen = true;
          _vm.renameName = _vm.booth.name;}}}),_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Delete'),expression:"'Delete'",modifiers:{"top":true}}],attrs:{"icon":"trash"},on:{"click":function($event){_vm.deleteOpen = true}}})],1)]),_c('div',{ref:"bubbles",staticClass:"bubbles"},[(
        _vm.$store.state.space.space.booth &&
        _vm.$store.state.space.space.booth.id == _vm.booth.id
      )?_c('div',{staticClass:"bubble"},[_c('div',{staticClass:"join leave",on:{"click":function($event){return _vm.$emit('leave')}}},[_c('font-awesome-icon',{attrs:{"icon":"sign-out-alt"}})],1),_c('div',{staticClass:"bubble-name"},[_vm._v("Leave")])]):_c('div',{staticClass:"bubble"},[_c('div',{staticClass:"join",on:{"click":_vm.join}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1),_c('div',{staticClass:"bubble-name"},[_vm._v("Join")])]),_vm._l((_vm.booth.users),function(user){return _c('UserBubble',{key:user.id,attrs:{"user":user}})}),(_vm.dropping)?_c('div',{staticClass:"bubble drop"}):_vm._e()],2),_c('SmallModal',{attrs:{"open":_vm.error != '',"title":"Error","yesText":"Okay"},on:{"yes":function($event){_vm.error = ''},"close":function($event){_vm.error = ''}}},[_vm._v(_vm._s(_vm.error))]),_c('SmallModal',{attrs:{"open":_vm.renameOpen,"title":"Rename Table","yesText":"Rename","noText":"Cancel"},on:{"yes":_vm.rename,"close":function($event){_vm.renameOpen = false;
      _vm.reload();}}},[_c('div',{staticClass:"rename"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"label"},[_vm._v("Table Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.renameName),expression:"renameName"}],attrs:{"type":"text","data-lpignore":"true"},domProps:{"value":(_vm.renameName)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.rename($event)},"input":function($event){if($event.target.composing){ return; }_vm.renameName=$event.target.value}}})]),(_vm.renameError != '')?_c('div',{staticClass:"error"},[_vm._v(_vm._s(_vm.renameError))]):_vm._e()])]),_c('SmallModal',{attrs:{"open":_vm.deleteOpen,"title":"Are you sure?","yesText":"Delete","noText":"Cancel"},on:{"yes":function($event){_vm.deleteOpen = false;
      _vm.deleteMe();},"close":function($event){_vm.deleteOpen = false}}},[_vm._v("You can't undo this.")]),_c('ContextMenu',{ref:"userMenu",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var meta = ref.meta;
return [_c('div',{on:{"click":function($event){return _vm.moveToMyTable(meta)}}},[_c('span',[_vm._v("Move To My Table")])]),_c('div',{on:{"click":function($event){return _vm.kick(meta)}}},[_c('span',[_vm._v("Kick")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }