<template>
  <Legal :source="source"></Legal>
</template>
<script>
import Legal from "@/components/Legal.vue";

import md from "@/assets/legal/privacy.md";

export default {
  name: "Privacy",
  components: { Legal },
  data() {
    return {
      source: md,
    };
  },
};
</script>
