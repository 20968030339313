<template>
  <div class="settings3">
    <router-link :to="{name: 'space', params: {code: $route.params.code}}" class="back">
      <img src="@/assets/back.svg" />
    </router-link>
    <div class="left-nav">
      <div class="left-nav-wrapper">
        <div class="title-hint">{{ kind }} settings</div>
        <div class="title">{{ title }}</div>
        <div class="nav-items">
          <slot name="nav"></slot>
        </div>
      </div>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Settings",
  props: {
    title: {
      type: String,
      required: true
    },
    kind: {
      type: String,
      required: true
    }
  },
  created() {
    this.load();
  },
  watch: {
    $route() {
      this.load();
    }
  },
  methods: {
    load() {
      this.$store.dispatch("space/loadState", {
        code: this.$route.params.code
      });
    }
  }
};
</script>
<style lang="scss" scoped>
$darkBG: rgb(231, 231, 231);
$bg: white;
$white: rgb(194, 194, 194);

.back {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  left: 2rem;
  top: 2rem;
  cursor: pointer;
}

.back:hover > img {
  animation: backflip 0.5s;
}

@keyframes backflip {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.settings3 {
  background: $bg;
  display: grid;
  height: 100vh;

  grid-template-columns: 1fr 2.3fr;
  grid-template-areas: "sidebar content";
}

.left-nav {
  grid-area: sidebar;
  background: $darkBG;
  display: flex;
  justify-content: flex-end;
}

.title {
  font-size: 25px;
  padding: 0rem 1rem;
}

.title-hint {
  font-size: 15px;
  color: grey;
  text-transform: uppercase;
  padding: 0rem 1rem;
}

.nav-items {
  margin-top: 2rem;
}

.left-nav-wrapper {
  padding: 5rem 2rem;
}

.content {
  grid-area: "content";
  padding: 5rem 2rem;
}
</style>