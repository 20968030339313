<template>
  <div>
    <Header>
      <div class="events">
        <div class="events-header">Your Spaces</div>
        <div class="events-spinner" v-if="$store.state.events.loading">
          <Spinner radius="30px" theme="dark"></Spinner>
        </div>
        <div
          class="no-events"
          v-if="!$store.state.events.loading && $store.state.events.events.length == 0"
        >No spaces yet 😮</div>
        <div class="event-list" v-if="!$store.state.events.loading">
          <div class="event" v-for="event in $store.state.events.events" :key="event.code">
            <EventCard :event="event"></EventCard>
          </div>
        </div>
        <div class="new-event">
          <BigButton @click="newSpaceOpen = true; focusBox()" color="black">Create a New Space</BigButton>
        </div>

        <!-- <SmallModal
          :open="errorOpen"
          title="Oof"
          yesText="Aight"
          :noButton="false"
          @yes="errorOpen=false"
          @close="errorOpen=false"
        >{{ error }}</SmallModal>-->

        <SmallModal
          :open="newSpaceOpen"
          title="Create A New Space"
          yesText="Create"
          noText="Cancel"
          @yes="newSpace"
          @close="newSpaceOpen = false"
        >
          <div class="new-space">
            <div class="field">
              <div class="label">Name your Space</div>
              <input
                type="text"
                ref="namebox"
                data-lpignore="true"
                v-model="name"
                @keypress.enter="newSpace"
              />
            </div>
            <div class="hint">
              You can choose who's allowed to be in this space through the space's
              settings.
            </div>
            <div class="error" v-if="errorOpen">{{ error }}</div>
            <!-- <form>
              <div class="field">
                <div class="label">Who should be allowed?</div>
                <div class="radio">
                  <input type="radio" value="password" id="password" name="auth" v-model="auth" />
                  <div class="radio-label">
                    <label for="password">Anyone with a Password</label>
                  </div>
                </div>
                <div class="radio">
                  <input type="radio" value="email" id="email" name="auth" v-model="auth" />
                  <div class="radio-label">
                    <label for="email">Anyone with an Email</label>
                  </div>
                </div>
                <div class="radio">
                  <input type="radio" value="whitelist" id="whitelist" name="auth" v-model="auth" />
                  <div class="radio-label">
                    <label for="whitelist">Email Whitelist / Domain Whitelist</label>
                  </div>
                </div>
              </div>
            </form>
            <div class="field" v-if="auth == 'password'">
              <div class="label">Choose a Password</div>
              <div class="password">
                <input
                  :type="visible ? 'text' : 'password'"
                  data-lpignore="true"
                  v-model="password"
                />
                <BigButton color="pink" style="margin-left: 0.5rem;" @click="visible = !visible">
                  <font-awesome-icon icon="eye"></font-awesome-icon>
                </BigButton>
              </div>
            </div>-->
            <!-- <div class="field">
              <div class="label">Choose your Space Password</div>
              <input type="password" autocomplete="off" data-lpignore="true" />
            </div>-->
          </div>
        </SmallModal>
      </div>
    </Header>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import BigButton from "@/components/BigButton.vue";
import Spinner from "@/components/Spinner.vue";
import EventCard from "@/components/EventCard.vue";
import SmallModal from "@/components/SmallModal.vue";

import { API } from "../api";

export default {
  name: "Events",
  components: { Header, BigButton, Spinner, EventCard, SmallModal },
  data() {
    return {
      error: "",
      errorOpen: false,
      newSpaceOpen: false,
      name: "",
      auth: "",
      password: "",
      visible: false
    };
  },
  created() {
    this.$store.dispatch("events/loadEvents");
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    focusBox() {
      this.$nextTick(() => {
        this.$refs.namebox.focus();
      });
    },
    newSpace() {
      this.errorOpen = false;
      API.new_event(this.name)
        .then(() => {
          this.newSpaceOpen = false;
          this.$store.dispatch("events/loadEvents");
          this.name = "";
        })
        .catch(e => {
          this.errorOpen = true;
          this.error = e;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.events {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.events-header {
  font-family: $headingFont;
  font-size: $headingFontSize;
  // animation: fadeInUp 0.5s ease 0s 1 forwards;
}

.new-event {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.event-list {
  margin-top: 2rem;
}

.event {
  margin-top: 1rem;
}

.no-events {
  margin-top: 2rem;
  margin-bottom: 2rem;
  opacity: 0.5;
}

.events-spinner {
  margin-top: 3rem;
}

.password {
  display: flex;
  align-items: center;
}

.error {
  color: red;
  margin-top: 2rem;
}
</style>
