<template>
  <div class="nav-item2" @click="$emit('input', to)" :class="{activenav: value === to}">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "NavItem",
  props: {
    value: {
      type: String
    },
    to: {
      type: String
    }
  }
};
</script>
<style lang="scss" scoped>
.nav-item2 {
  font-size: 17px;
  border-radius: 5px;
  padding: 0.3rem 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.nav-item2:hover {
  background: rgb(237, 237, 237);
}

.activenav {
  background: white !important;
}
</style>