<template>
  <div
    class="bubble"
    :class="{ sleek: sleek }"
    draggable="true"
    :key="user.id"
    @dragstart="startDrag($event, user)"
    @dragend="$emit('stopdrag')"
    @contextmenu.prevent="$refs.userMenu.open($event, user)"
  >
    <div class="profile-picture2" :class="{ sleek: sleek }">
      <ProfilePicture :user="user" :borderColor="roleColor"></ProfilePicture>
    </div>
    <div class="bubble-name" :style="{ color: roleColor }">
      {{ user.display_name }}
    </div>

    <ContextMenu ref="userMenu">
      <template slot-scope="{ meta }">
        <div @click="moveToMyTable(meta)">
          <span>Move To My Table</span>
        </div>
        <div @click="kick(meta)">
          <span>Kick</span>
        </div>
      </template>
    </ContextMenu>
  </div>
</template>
<script>
import ProfilePicture from "@/components/ProfilePicture.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import { API } from "../api";

export default {
  name: "UserBubble",
  components: { ProfilePicture, ContextMenu },
  props: {
    user: {
      type: Object,
      required: true,
    },
    sleek: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    roleColor() {
      const filtered = this.user.roles.filter((r) => !r.default);
      if (filtered.length == 0) return null;
      return filtered[0].color;
    },
  },
  methods: {
    startDrag(event, user) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("user", JSON.stringify(user));
      this.$emit("dragging");
    },
    moveToMyTable(user) {
      if (this.$store.state.space.space.booth == null) {
        this.$toasted.show("You're not in any table.", {
          position: "bottom-center",
          duration: 3000,
          theme: "bubble",
          action: {
            text: "Dismiss",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
        return;
      }

      API.move_user(
        this.$route.params.code,
        user.id,
        this.$store.state.space.space.room.id,
        this.$store.state.space.space.booth.id
      )
        .then(this.reload)
        .catch((e) => {
          this.$toasted.show(e, {
            position: "bottom-center",
            duration: 3000,
            theme: "bubble",
            action: {
              text: "Dismiss",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
        });
    },
    kick(user) {
      API.move_user(
        this.$route.params.code,
        user.id,
        this.$store.state.space.space.room.id,
        -1
      )
        .then(this.reload)
        .catch(this.toastError);
    },
    toastError(e) {
      this.$toasted.show(e, {
        position: "bottom-center",
        duration: 3000,
        theme: "bubble",
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    reload() {
      this.$store.dispatch("space/loadState", {
        code: this.$route.params.code,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(145, 145, 145);
  font-size: 14px;
  margin-top: 1rem;
  cursor: pointer;
  background: transparent;
  animation: pop 0.1s;
}

.bubble.sleek {
  flex-direction: row;
}

.bubble .bubble-name {
  margin-top: 0.5rem;
  //   background: rgba(0, 0, 0, 0.253);
  border-radius: 5px;
  padding: 2px 10px;
  text-align: center;
}

.bubble img {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 2px solid rgb(139, 89, 255);
}

.profile-picture2 {
  width: 3rem;
  height: 3rem;
}

.profile-picture2.sleek {
  width: 2.3rem;
  height: 2.3rem;
}

// .initials {
//   width: 3rem;
//   height: 3rem;
// }

// .sleek .initials {
//   width: 2rem !important;
//   height: 2rem !important;
// }
</style>