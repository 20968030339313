<template>
  <transition name="bounce2">
    <div class="small-modal" v-if="open">
      <div
        class="small-modal-inner"
        v-if="open"
        v-on-clickaway="ca"
        :style="{ width: width }"
      >
        <div class="small-modal-title">{{ title }}</div>
        <div class="small-modal-content">
          <slot></slot>
        </div>
        <div class="small-modal-buttons">
          <BigButton class="llb" @click="$emit('close')" v-if="noButton">{{
            noText
          }}</BigButton>
          <BigButton color="black" @click="$emit('yes')" v-if="yesButton">{{
            yesText
          }}</BigButton>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import BigButton from "@/components/BigButton.vue";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "SmallModal",
  mixins: [clickaway],
  components: { BigButton },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    shadowClose: {
      type: Boolean,
      default: true,
    },
    noText: {
      default: "No",
    },
    yesText: {
      default: "Yes",
    },
    title: {
      default: "Are you sure?",
    },
    width: {
      default: "16rem",
      type: String,
    },
    noButton: {
      default: true,
      type: Boolean,
    },
    yesButton: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    ca() {
      if (this.shadowClose) this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.small-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: #0000005e;
}

.small-modal-inner {
  padding: 1.5rem 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 14px 14px 7px #00000029;
}

.small-modal-title {
  text-align: left;
  font-family: "Playfair Display";
  font-size: 24px;
}

.small-modal-content {
  padding-top: 1rem;
  padding-bottom: 2rem;
  font-size: 15px;
}

.small-modal-buttons {
  text-align: right;
}

.llb {
  margin-right: 0.5rem;
}

.bounce2-enter-active {
  transform-origin: center center;
  animation: nofade 0.2s;
}

.bounce2-enter-active > .small-modal-inner {
  animation: bounce2-in 0.2s;
}

@keyframes bounce2-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes nofade {
  0% {
    opacity: 0.99999;
  }
  100% {
    opacity: 1;
  }
}
</style>
