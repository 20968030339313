<template>
  <div class="auth-mode">
    <div class="boxes">
      <div
        class="box"
        v-for="authStyle in $store.state.space.space.space.allowed_auths"
        :key="authStyle"
      >
        <label>
          <input type="checkbox" :value="authStyle" v-model="styles" @change="change" />
          {{ human(authStyle) }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AuthMode",
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      styles: [],
    };
  },
  watch: {
    value() {
      this.styles = this.value.slice();
    },
  },
  mounted() {
    this.styles = this.value.slice();
  },
  methods: {
    human(authStyle) {
      if (authStyle === "email") return "Email";
      if (authStyle === "google") return "Google";
      if (authStyle === "mit") return "MIT Touchstone";
      if (authStyle === "quill") return "HackMIT Quill";
    },
    change() {
      this.$emit("input", this.styles);
    },
  },
};
</script>
<style lang="scss" scoped>
.auth-mode {
  user-select: none;
}
</style>