import axios from 'axios'

const state = {
    upcoming: [],
    loading: true,
}

// getters
const getters = {}

// actions
const actions = {
    getDates(context) {
        return new Promise((resolve) => {
            axios.get('/api/date/upcoming').then((res) => {
                context.commit('setUpcomingDates', res.data);
                context.commit('setLoading', false);
                resolve();
            }).catch(() => {
                context.commit('setUpcomingDates', []);
                context.commit('setLoading', false);
                resolve();
            });
        });
    },
}

// mutations
const mutations = {
    setUpcomingDates(state, dates) {
        state.upcoming = dates;
    },
    setLoading(state, loading) {
        state.loading = loading;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}