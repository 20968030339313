<template>
  <router-link :to="{name: 'space', params: { code: event.code }}" target="_blank">
    <div class="event-card">
      <div class="date-desc">
        <div class="date-title">{{ event.name }}</div>
        <!-- <div class="date-hint">
          <a :href="'mailto:' + date.match_info.email">{{ date.match_info.email }}</a>
        </div>-->
      </div>
      <div class="edit">
        <font-awesome-icon icon="external-link-alt"></font-awesome-icon>
      </div>
      <!-- <div class="love-button">
        <font-awesome-icon icon="pen"></font-awesome-icon>
        <div class="tag draft" v-if="!event.published">Draft</div>
        <div class="tag live" v-else>Live</div>
      </div>-->
    </div>
  </router-link>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
// import moment from "moment-timezone";

export default {
  name: "EventCard",
  mixins: [clickaway],
  components: {},
  props: ["event"],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.event-card {
  cursor: pointer;
  border-radius: 10px;
  width: 22rem;
  height: 4rem;

  display: grid;
  grid-template-columns: auto 5rem;
  grid-template-rows: 1fr;

  grid-template-areas: "desc edit";

  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 3px rgba(0, 0, 0, 0.25);

  color: black;
}

.event-card:hover {
  background: rgb(238, 238, 238);
}

.edit {
  grid-area: edit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $lightGray;
}

.date-desc {
  grid-area: desc;
  padding: 0.5rem 1rem;
  display: flex;
  // justify-content: start;
  align-items: center;
}

.date-title {
  font-weight: bold;
  font-size: 18px;
}

.date-hint {
  font-size: 14px;
  color: $lightGray;
  text-decoration: underline;
  cursor: pointer;
}

.date-hint a {
  font-size: 14px;
  color: $lightGray;
  text-decoration: underline;
  cursor: pointer;
}

.date-button {
  grid-area: button;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $lightGray;
}

.love-button {
  grid-area: love;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dfdfdf;
}

.date-button:hover {
  color: rgb(94, 94, 94);
}

.date-dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: solid 1px #e0e0e0;
  padding: 1rem;
  z-index: 2;
  user-select: none;
}

.launch-button {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag {
  font-size: 15px;
  color: white;
  border-radius: 10px;
  padding: 0.2rem 0.6rem;
}

.live {
  background: #f78fb3;
}

.draft {
  background: #778beb;
}
</style>
