<template>
  <div class="set-content" v-if="to === value">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Content",
  props: {
    to: {
      type: String
    },
    value: {
      type: String
    }
  }
};
</script>
