<template>
  <div id="splash">
    <Header>
      <div class="splash-body" id="top">
        <div class="section-wrapper fill-screen">
          <div class="section right">
            <div class="hero-text" data-aos="fade-up">
              <div>
                <div class="big-header">Video chat for social groups.</div>
                <div class="subtext">
                  Zoom calls are exhausting. FireMeet allows people to naturally
                  move between tables.
                </div>
                <div class="cta-bar">
                  <router-link to="/login">
                    <BigButton color="black">Create A Space</BigButton>
                  </router-link>
                  <router-link to="/login">
                    <BigButton color="white">Log In</BigButton>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="hero">
              <div class="hero-video-wrapper">
                <video
                  data-aos="pop"
                  src="@/assets/hero.mp4"
                  class="hero-video"
                  autoplay
                  muted
                  loop
                  playsinline
                ></video>
              </div>
            </div>
          </div>
          <div
            class="hero-arrow"
            :style="{ opacity: scrollTop <= 100 ? 0.3 : 0 }"
            v-scroll-to="{
              el: '#first',
              duration: 500,
              easing: 'ease',
              offset: -100,
            }"
          >
            <font-awesome-icon icon="angle-down"></font-awesome-icon>
          </div>
        </div>
        <!-- <div class="filler"></div> -->
        <div class="section-wrapper new" id="first">
          <div class="section left">
            <div class="hero-text" data-aos="fade-up">
              <div>
                <div class="big-header">
                  Need more control?
                  <span class="accent">Sure thing!</span>
                </div>
                <div class="subtext">
                  Those in charge can rearrange tables by dragging. Make tables
                  private by locking them.
                </div>
              </div>
            </div>
            <div class="hero">
              <div class="hero-video-wrapper">
                <video
                  data-aos="pop"
                  data-aos-offset="200"
                  src="@/assets/lock.mp4"
                  class="hero-video top"
                  autoplay
                  muted
                  loop
                  playsinline
                ></video>
              </div>
              <div class="video-space"></div>
              <div class="hero-video-wrapper">
                <video
                  data-aos="pop"
                  data-aos-offset="200"
                  src="@/assets/move.mp4"
                  class="hero-video bottom"
                  autoplay
                  muted
                  loop
                  playsinline
                ></video>
              </div>
            </div>
          </div>
        </div>
        <div class="filler"></div>
        <div class="section center">
          <div class="hero-text center">
            <div class="big-header" data-aos="fade-up">
              Granular permissions
              <span class="accent">your way.</span>
            </div>
            <div
              class="subtext center"
              data-aos="fade-up"
            >FireMeet lets you customize who's allowed to do what.</div>
          </div>
        </div>
        <div class="roles">
          <div class="role" data-aos="fade-up" data-aos-delay="100">
            <div class="role-list">
              <div class="role-box blue">
                <div class="dot"></div>
                <div class="role-title">Instructor</div>
              </div>
              <div class="role-box red">
                <div class="dot"></div>
                <div class="role-title">Teaching Assistant</div>
              </div>
              <div class="role-box grey">
                <div class="dot"></div>
                <div class="role-title">Student</div>
              </div>
            </div>
            <div class="desc">Office Hours</div>
          </div>
          <div class="role" data-aos="fade-up" data-aos-delay="150">
            <div class="role-list">
              <div class="role-box green">
                <div class="dot"></div>
                <div class="role-title">Staff</div>
              </div>
              <div class="role-box blue">
                <div class="dot"></div>
                <div class="role-title">Judge</div>
              </div>
              <div class="role-box red">
                <div class="dot"></div>
                <div class="role-title">Mentor</div>
              </div>
              <div class="role-box grey">
                <div class="dot"></div>
                <div class="role-title">Hacker</div>
              </div>
            </div>
            <div class="desc">Hackathon</div>
          </div>
          <div class="role" data-aos="fade-up" data-aos-delay="200">
            <div class="role-list">
              <div class="role-box blue">
                <div class="dot"></div>
                <div class="role-title">Host</div>
              </div>
              <div class="role-box grey">
                <div class="dot"></div>
                <div class="role-title">Attendee</div>
              </div>
            </div>
            <div class="desc">Party</div>
          </div>
        </div>
        <div class="filler"></div>
        <div class="section-wrapper">
          <div class="section right">
            <div class="hero-text" data-aos="fade-up">
              <div>
                <div class="big-header">
                  Draw and code
                  <span class="accent">together.</span>
                </div>
                <div class="subtext">
                  Teach or collaborate with real-time multiplayer code editor
                  and whiteboard.
                </div>
              </div>
            </div>
            <div class="hero">
              <div class="hero-video-wrapper">
                <div
                  class="hero-video"
                  style="overflow: hidden"
                  data-aos="pop"
                  data-aos-offset="400"
                >
                  <video
                    style="width: 101%"
                    src="@/assets/draw.mp4"
                    autoplay
                    muted
                    loop
                    playsinline
                  ></video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="filler"></div>
        <div class="section-wrapper">
          <div class="section left">
            <div class="hero-text" data-aos="fade-up">
              <div>
                <div class="big-header">
                  Scale to
                  <span class="accent">thousands</span> of people effortlessly.
                </div>
                <div class="subtext">
                  We use fancy distributed computing to scale to thousands of people in the
                  same space without lag.
                </div>
              </div>
            </div>
            <div class="hero">
              <div class="hero-video-wrapper">
                <video
                  data-aos="pop"
                  src="@/assets/hero.mp4"
                  class="hero-video"
                  autoplay
                  muted
                  loop
                  playsinline
                ></video>
              </div>
            </div>
          </div>
        </div>-->
        <div class="video-space"></div>
        <div class="video-space"></div>
        <div class="line"></div>
        <div class="video-space"></div>
        <div class="section-wrapper">
          <div class="section center" data-aos="fade-up" data-aos-offset="200">
            <div class="hero-text center">
              <div class="big-header center">Give it a spin, it's free!</div>
              <div class="cta-final">
                <router-link to="/login">
                  <BigButton color="black">Create A Space</BigButton>
                </router-link>

                <a href="mailto:hello@firemeet.io">
                  <BigButton color="white">Contact Us</BigButton>
                </a>
              </div>
              <div class="tiny-hint">
                Using FireMeet for &#8805; 100 people?
                <br />Drop me a line so I can raise the default cap.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </Header>
  </div>
</template>

<script>
// // @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BigButton from "@/components/BigButton.vue";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "Splash",
  components: {
    Header,
    BigButton,
    Footer
  },
  data() {
    return {
      logoPop: false,
      scrollTop: 0
    };
  },
  created() {
    document.addEventListener("scroll", this.scroll);
    AOS.init();
  },
  destroyed() {
    document.removeEventListener("scroll", this.scroll);
  },
  methods: {
    clickTest() {
      this.$http.get("/api/ping").then(res => {
        console.log(res);
      });
    },
    scroll() {
      this.scrollTop = window.scrollY;
    }
  }
};
</script>

<style lang="scss" scoped>
.splash-body {
  // margin-top: 5rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.line {
  width: 100%;
  border-bottom: 2px dashed #d8d8d8;
}

@media (max-width: $breakpoint-mobile) {
  .splash-body {
    margin-top: 0rem;
  }
}

.cta-final {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 10rem;
}

.cta-final a {
  margin-bottom: 1rem;
  // width: 10rem;
}

// .cta-final .big-button {
//   width: 100%;
// }

.accent {
  // color: #0652dd;
  color: #f0932b;
  font-weight: bold;
}

.tiny-hint {
  color: grey;
  max-width: 30rem;
  text-align: center;
  margin-top: 1rem;
  font-size: 15px;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

.video-space {
  height: 6rem;
}

.filler {
  height: 10rem;
}

@media (max-width: $breakpoint-mobile) {
  .filler {
    height: 5rem;
  }
}

.hero-arrow {
  // color: #f0932b;
  opacity: 0.3;
  font-size: 40px;
  margin-top: 2rem;
  cursor: pointer;
}

.hero-arrow svg {
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  transition: all cubic-bezier(0.8, 0.1, 0.27, 1);
  animation-name: arrow-bounce;
}

@keyframes arrow-bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0);
  }
}

.hero-video-wrapper {
  max-width: 23rem;
}

.subtext.center {
  text-align: center;
  max-width: 50rem;
}

@media (max-width: $breakpoint-mobile) {
  .subtext.center {
    text-align: left;
  }
}

.hero-text.center {
  display: flex;
  flex-direction: column;
}

.section {
  display: grid;
  grid-gap: 5rem;
  max-width: 60rem;
  margin: 0rem 3rem;
}

.section.right {
  grid-template-areas: "text hero";
  grid-template-columns: 1fr auto;
  grid-template-rows: 100%;
}

.section.left {
  grid-template-areas: "hero text";
  grid-template-columns: auto 1fr;
  grid-template-rows: 100%;
}

@media (max-width: $breakpoint-mobile) {
  .section.left {
    grid-template-areas:
      "text"
      "hero";
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
    grid-gap: 2rem;
  }
}

@media (max-width: $breakpoint-mobile) {
  .section.right {
    grid-template-areas:
      "text"
      "hero";
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
    grid-gap: 2rem;
  }
}

.section.center {
  grid-template-areas:
    "text"
    "hero";
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
}

.hero-text {
  grid-area: text;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hero {
  grid-area: hero;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.big-header {
  font-family: "Playfair Display";
  font-size: 50px;
}

.big-header.center {
  text-align: center;
}

.subtext {
  font-family: "Baloo Chettan 2";
  font-size: 26px;
  margin-top: 2rem;
  color: #57576d;
}

.role-list {
  grid-area: role-list;
  padding: 1rem;
}

.role-box {
  font-size: 15px;
  border-radius: 10px;
  border: 2px solid black;
  padding: 0.3rem 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  opacity: 0.8;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  background: red;
  border-radius: 100%;
  margin-right: 0.4rem;
}

.role-title {
  font-weight: bold;
}

$roleBlue: #1e90ff;
.role-box.blue .role-title {
  color: $roleBlue;
}
.role-box.blue .dot {
  background: $roleBlue;
}
.role-box.blue {
  border-color: $roleBlue;
}

$roleRed: #ff6348;
.role-box.red .role-title {
  color: $roleRed;
}
.role-box.red .dot {
  background: $roleRed;
}
.role-box.red {
  border-color: $roleRed;
}

$roleGrey: #a4b0be;
.role-box.grey .role-title {
  color: $roleGrey;
}
.role-box.grey .dot {
  background: $roleGrey;
}
.role-box.grey {
  border-color: $roleGrey;
}

$roleGreen: #b33771;
.role-box.green .role-title {
  color: $roleGreen;
}
.role-box.green .dot {
  background: $roleGreen;
}
.role-box.green {
  border-color: $roleGreen;
}

.desc {
  grid-area: desc;
  text-align: center;
  font-size: 19px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d8d8d8;
}

.cta-bar {
  margin-top: 2rem;
}

.cta-bar .big-button {
  margin-right: 1rem;
}

.hero-video {
  border-radius: 20px;
  width: 100%;
  // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  // box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
  //   rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  //   rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.section-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: $breakpoint-mobile) {
  .section-wrapper {
    padding-top: 2rem;
  }
}

// Colors
$bg-color: #fff3e5;
$dot-color: #e2cfb8;

// Dimensions
$dot-size: 2px;
$dot-space: 22px;

.section-wrapper.new {
  background: #fff3e5;
  background: linear-gradient(
        90deg,
        $bg-color ($dot-space - $dot-size),
        transparent 1%
      )
      center,
    linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
    $dot-color;
  background-size: $dot-space $dot-space;
}

@media (min-width: $breakpoint-mobile) {
  .section-wrapper.fill-screen {
    height: calc(100vh - 4rem);
  }
}

.hero-video.bottom {
  border-radius: 20px 20px 0px 0px;
}

.hero-video.top {
  border-radius: 0px 0px 20px 20px;
}

.splash {
  display: grid;
  margin-top: 3rem;
  grid-template-columns: 100%;
  grid-template-rows: auto 2rem 3rem 3rem auto;
  grid-template-areas:
    "blurb"
    "."
    "buttons"
    "."
    "steps";
}

.blurb {
  grid-area: blurb;
  display: flex;
  justify-content: center;

  opacity: 0;
  animation: fadeInUp 0.5s ease 0s 1 forwards;
  padding: 0 2rem;
}

.blurb-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.headline {
  font-size: 35px;
  font-family: "Playfair Display";
}

.byline {
  color: #3d3d3d;
  margin-top: 1rem;
}

.buttons {
  grid-area: buttons;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  animation: fadeInUp 0.5s ease 0.1s 1 forwards;
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps {
  grid-area: steps;
  background: rgb(255, 177, 177);
  background: linear-gradient(
    45deg,
    rgba(255, 177, 177, 1) 0%,
    rgba(255, 177, 177, 1) 33%,
    rgba(144, 144, 255, 1) 73%,
    rgba(255, 0, 250, 1) 100%
  );

  min-height: 20rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.roles {
  background: rgb(255, 177, 177);
  background: linear-gradient(
    45deg,
    rgba(255, 177, 177, 1) 0%,
    rgba(255, 177, 177, 1) 33%,
    rgba(144, 144, 255, 1) 73%,
    rgba(255, 0, 250, 1) 100%
  );

  width: 100%;
  min-height: 24rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: $breakpoint-mobile) {
  .roles {
    flex-direction: column;
  }
  .steps {
    flex-direction: column;
  }
}

.role {
  width: 12rem;
  height: 16rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 14px 20px 0px #00000021;
  margin: 2rem 2rem;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 3.2rem auto;

  grid-template-areas:
    "desc"
    "role-list";
}

.step {
  width: 12rem;
  height: 15rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 14px 20px 0px #00000021;
  margin: 2rem 2rem;

  display: grid;
  grid-template-columns: auto 75%;
  grid-template-rows: auto 3.2rem;

  grid-template-areas:
    "graphic graphic"
    "number desc";
}

.graphic {
  grid-area: graphic;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.graphic > img {
  width: 10.5rem;
}

.number {
  grid-area: number;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #808080;
  font-size: 17px;
}

.card1 {
  opacity: 0;
  animation: fadeInUp 0.5s ease 0.2s 1 forwards;
}

.card2 {
  opacity: 0;
  animation: fadeInUp 0.5s ease 0.3s 1 forwards;
}

.card3 {
  opacity: 0;
  animation: fadeInUp 0.5s ease 0.4s 1 forwards;
}

.space {
  width: 20px;
}

.users {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.users-brag {
  font-size: 15px;
}

.uni-logo.animate1 {
  animation: fadeInUp 0.5s ease 0.1s 1 forwards;
}

.uni-logo.animate2 {
  animation: fadeInUp 0.5s ease 0.2s 1 forwards;
}

.uni-logo.animate3 {
  animation: fadeInUp 0.5s ease 0.3s 1 forwards;
}

.uni-logo.animate4 {
  animation: fadeInUp 0.5s ease 0.4s 1 forwards;
}

.uni-logo.animate5 {
  animation: fadeInUp 0.5s ease 0.5s 1 forwards;
}

[data-aos="pop"] {
  animation: pop 0.5s forwards paused;
  &.aos-animate {
    animation: pop 0.5s forwards;
  }

  @keyframes pop {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.universities {
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;

  display: grid;
  width: 100%;
  max-width: 50rem;
  padding: 0 5rem;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  row-gap: 1.5rem;
}

.uni-logo .logow {
  width: 3rem;
}

.uni-logo .logoh {
  height: 2rem;
}

.uni-logo {
  opacity: 0;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen-init {
  grid-area: steps;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen-init img {
  width: 40rem;
  border-radius: 15px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 3px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeInUp 0.5s ease 0.2s 1 forwards;
}
</style>