<template>
  <div class="login-stack">
    <template v-if="authStyle('email')">
      <div class="login-hint">What's your email?</div>
      <div class="login-box">
        <input
          type="text"
          v-model="email"
          placeholder="e.g. example@sup.com"
          @keypress.enter="attemptLogin"
        />
        <BigButton color="pink" :loading="loading" @click="attemptLogin">
          <font-awesome-icon icon="caret-right"></font-awesome-icon>
        </BigButton>
      </div>
      <div class="policy-blurb">
        By signing up you agree that you are 13 years and older and agree with our
        <a
          href="/privacy"
          target="_blank"
        >privacy policy</a> and
        <a href="/terms" target="_blank">terms of service.</a>
      </div>
      <div class="will-login" v-if="mit">(Will login using Google)</div>
      <div class="login-or" v-if="!mit">
        <div class="login-line"></div>
        <div class="login-or-tex">OR</div>
        <div class="login-line"></div>
      </div>
    </template>
    <div class="touchstone" v-if="authStyle('mit')">
      <a :href="touchstoneHref">
        <BigButton color="black">
          <span class="social-icon">
            <font-awesome-icon :icon="['fas', 'university']" />
          </span>
          MIT Touchstone
        </BigButton>
      </a>
    </div>
    <div class="touchstone" v-if="!mit && authStyle('google')">
      <a :href="googleHref">
        <BigButton color="black">
          <span class="social-icon">
            <font-awesome-icon :icon="['fab', 'google']" />
          </span>
          Login with Google
        </BigButton>
      </a>
    </div>
    <SmallModal
      title="Uh oh"
      :open="error"
      @close="error = false"
      @yes="error = false"
      :noButton="false"
      yesText="Oof"
    >{{ errorMessage }}</SmallModal>

    <SmallModal title="Check Your Email 😮" :open="success" :noButton="false" :yesButton="false">
      Yay! We sent you a link in the email to log-in. Please make sure to check
      your spam folder.
    </SmallModal>
  </div>
</template>
<script>
import BigButton from "@/components/BigButton.vue";
import SmallModal from "@/components/SmallModal.vue";

export default {
  name: "LoginStack",
  components: { BigButton, SmallModal },
  props: {
    space: {
      type: Boolean,
      default: false
    },
    code: {
      type: String
    },
    auth: {
      type: Array
    }
  },
  data() {
    return {
      email: "",
      loading: false,
      success: false,
      errorMessage: "",
      error: false
    };
  },
  created() {
    this.$store.dispatch("constants/getConstants");
  },
  computed: {
    mit() {
      return this.email.endsWith("@gmail.com");
    },
    googleHref() {
      return this.space
        ? "/api/login/google?code=" + this.code
        : "/api/login/google";
    },
    touchstoneHref() {
      return this.space
        ? "/api/login/idp?space_code=" + this.code
        : "/api/login/idp";
    },
    magicHref() {
      const base = `/api/login/send_magic?email=${this.email}`;
      if (this.space) {
        return base + "&code=" + this.code;
      }
      return base;
    }
  },
  methods: {
    authStyle(s) {
      if (!this.space) return true;
      return this.auth.indexOf(s) >= 0;
    },
    attemptLogin() {
      // if (this.email.endsWith("@gmail.com")) {
      //   this.loading = true;
      //   window.location.replace(this.googleHref);
      //   return;
      // }

      this.loading = true;
      setTimeout(() => {
        this.$http
          .get(this.magicHref)
          .then(() => {
            this.loading = false;
            this.success = true;
          })
          .catch(r => {
            this.loading = false;
            if (r.response.status == 429) {
              this.errorMessage = "Too many requests.";
            } else {
              this.errorMessage = r.response.data.error;
            }
            this.error = true;
          });
      }, 5);
    }
  }
};
</script>
<style lang="scss" scoped>
.login-stack {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login-header {
  font-family: $headingFont;
  font-size: $headingFontSize;
  // animation: fadeInUp 0.5s ease 0s 1 forwards;
}

.login-hint {
  margin-top: 3rem;
  color: black;
}

.login-box {
  margin-top: 1rem;
  width: 20rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.login-box input {
  outline: none;
  text-align: left;
  color: #35495e;
  padding: 0.8rem 1.2rem;
  border-radius: 5px;
  border: 1px solid #969696;
  background: #fff;
  font-size: 15px;
  transition: 0.4s;
  width: 13rem;
}

.login-box input:hover {
  border: 1px solid rgb(68, 68, 68);
}

.login-box input:focus {
  border: 1px solid rgb(109, 68, 255);
}

.login-box input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(199, 199, 199);
  opacity: 1; /* Firefox */
}

$orColor: rgb(116, 116, 116);

.login-or {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: $orColor;
}

.login-line {
  width: 5rem;
  height: 0;
  border-top: 1px solid $orColor;
  margin: 0rem 0.5rem;
}

.touchstone {
  margin-top: 1.5rem;
}

.currently-at {
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 0rem 3rem;
  text-align: center;
  color: #525252;
  max-width: 30rem;
  width: 100%;
  box-sizing: border-box;
}

.college-list {
  margin-top: 1rem;
  text-align: center;
  color: black;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.will-login {
  margin-top: 2rem;
}

.policy-blurb {
  width: 15rem;
  font-size: 12px;
  margin-top: 2rem;
  text-align: center;
  color: darkgrey;
}

.policy-blurb a {
  text-decoration: underline;
  color: #9292cc;
}

.policy-blurb a:hover {
  color: white;
  background: #9292cc;
}

.social-icon {
  margin-right: 0.5rem;
  font-size: 0.8em;
}
</style>