<template>
  <Header>
    <div class="profile">
      <div class="profile-header">Profile</div>
      <div class="profile-editor" v-if="!$store.state.space.loading">
        <div class="profile-picture">
          <div class="pp-wrapper">
            <ProfilePicture :user="$store.state.space.space.user"></ProfilePicture>
          </div>
        </div>
        <div class="change-image">
          <!-- <BigButton
            color="white"
            @click="$refs.upload.click()"
            :loading="imageLoading"
          >Change Image</BigButton>-->
        </div>
        <div class="field">
          <div class="label">Display Name</div>
          <input type="text" v-model="$store.state.space.space.user.display_name" />
        </div>

        <div class="profile-buttons">
          <BigButton color="white" @click="back" v-if="!$store.state.space.space.user.wizard">Cancel</BigButton>
          <BigButton color="black" @click="save">Save</BigButton>
        </div>
      </div>
    </div>

    <input
      type="file"
      id="upload"
      ref="upload"
      style="display:none;"
      accept="image/*"
      @change="uploadImage"
    />

    <SmallModal
      :open="errorOpen"
      title="Oof"
      yesText="Aight"
      :noButton="false"
      @yes="errorOpen=false"
      @close="errorOpen=false"
    >{{ error }}</SmallModal>
  </Header>
</template>
<script>
import Header from "@/components/Header.vue";
import BigButton from "@/components/BigButton.vue";
import SmallModal from "@/components/SmallModal.vue";
import ProfilePicture from "@/components/ProfilePicture.vue";

import { API } from "../api";

export default {
  name: "Profile",
  components: { Header, BigButton, SmallModal, ProfilePicture },
  data() {
    return {
      error: "",
      errorOpen: false,
      imageLoading: false
    };
  },
  methods: {
    save() {
      API.set_profile(
        this.$route.params.code,
        this.$store.state.space.space.user.display_name
      )
        .then(() => {
          this.$store.dispatch("space/loadState", {
            code: this.$route.params.code
          });
          this.back();
        })
        .catch(e => {
          this.errorOpen = true;
          this.error = e;
        });
    },
    back() {
      this.$router.push({
        name: "space",
        params: { code: this.$route.params.code }
      });
    },
    uploadImage(event) {
      this.errorOpen = false;
      this.error = "";

      const file = event.target.files[0];
      const mb = file.size / 1000000;

      if (mb > 8) {
        this.error = "Image size can't be more than 8 MB.";
        this.errorOpen = true;
        return;
      }

      // var formData = new FormData();
      // formData.append("file", file);
      // axios.post(`/api/${this.$route.params.code}/profile_picture`, formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      // })
    }
  }
};
</script>
<style lang="scss" scoped>
.profile-header {
  font-family: $headingFont;
  font-size: $headingFontSize;
}

.profile {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-editor {
  width: 15rem;
  margin-top: 2rem;
}

.profile-buttons {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

// .profile-picture {
//   width: 10rem;
//   height: 10rem;
// }

.pp-wrapper {
  width: 8rem;
  height: 8rem;
}

.profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-image {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
</style>