<template>
  <div class="vid-chat" :class="{ meethidden: !visible }">
    <div class="meet" :class="{ meethidden: loading }"></div>
    <div class="spinner" v-if="loading">
      <div class="l">
        <Spinner radius="40px" theme="light"></Spinner>Joining Table
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.vue";

function lsDefault(key, defaultValue) {
  const result = localStorage.getItem(key);
  if (result === null) return defaultValue;
  return result === "true" ? true : false;
}

export default {
  name: "VidChat",
  components: { Spinner },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      jitsi: {},
      loading: false,
    };
  },
  watch: {
    uuid() {
      this.unloadVideo();
      if (this.uuid) {
        this.loadVideo();
      }
    },
  },
  mounted() {
    if (this.uuid) {
      this.unloadVideo();
      this.loadVideo();
    }
  },
  methods: {
    unloadVideo() {
      document.getElementsByClassName("meet")[0].innerHTML = "";
    },
    loadVideo() {
      const interfaceOptions = {
        APP_NAME: "FireMeet",
        NATIVE_APP_NAME: "FireMeet",
        DISPLAY_WELCOME_PAGE_CONTENT: false,
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "desktop",
          "closedcaptions",
          "desktop",
          "fodeviceselection",
          "hangup",
          "chat",
          "etherpad",
          "settings",
          "videoquality",
          "filmstrip",
          "shortcuts",
          "tileview",
          "download",
          "help",
          "mute-everyone",
        ],
        SETTINGS_SECTIONS: ["devices", "language"],
        DEFAULT_REMOTE_DISPLAY_NAME: "FireMeet User",
        SHOW_CHROME_EXTENSION_BANNER: false,
        MOBILE_APP_PROMO: false,
      };

      const audioMuted = lsDefault("audioMuted", false);
      const videoMuted = lsDefault("videoMuted", false);

      const config = {
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        startWithAudioMuted: audioMuted,
        startWithVideoMuted: videoMuted,
      };

      this.loading = true;
      this.jitsi = new window.JitsiMeetExternalAPI("meet.jit.si", {
        roomName: "firemeet/" + this.uuid,
        parentNode: document.querySelector(".meet"),
        interfaceConfigOverwrite: interfaceOptions,
        configOverwrite: config,
        onload: () => {
          this.loading = false;
          this.jitsi.executeCommand(
            "subject",
            this.$store.state.space.space.booth.name
          );
          this.jitsi.executeCommand(
            "displayName",
            this.$store.state.space.space.user.display_name
          );
        },
      });

      this.jitsi.addEventListener("readyToClose", () => {
        this.$emit("leave");
      });

      this.jitsi.addEventListener("audioMuteStatusChanged", (data) => {
        localStorage.setItem("audioMuted", data.muted ? "true" : "false");
      });

      this.jitsi.addEventListener("videoMuteStatusChanged", (data) => {
        localStorage.setItem("videoMuted", data.muted ? "true" : "false");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.meethidden {
  display: none;
}

.vid-chat,
.meet {
  width: 100%;
  height: 100%;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.l {
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
}
</style>