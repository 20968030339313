<template>
  <div class="logo-wrapper" :class="{bw: bw}">
    <div class="logo">
      <img class="logo-image" src="@/assets/bonfire.svg" />
      FireMeet
    </div>
  </div>
</template>
<script>
export default {
  name: "Logo",
  props: {
    bw: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
.logo-wrapper {
  display: inline-block;
}

.bw {
  filter: saturate(0%);
  color: rgb(65, 63, 75);
}

.logo {
  font-family: $logoFont;
  font-size: $logoFontSize;
  font-weight: 600;
  text-align: center;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
}

.logo-image {
  width: 1.5rem;
  margin-right: 0.5rem;
}
</style>