<template>
  <Header>
    <div class="space-login">
      <template v-if="loading">
        <Spinner radius="30px" theme="dark"></Spinner>
      </template>
      <template v-if="!loading && !errorOpen">
        <div class="space-header">{{ spaceName }}</div>
        <LoginStack
          :space="true"
          :code="$route.params.code"
          :auth="auth"
        ></LoginStack>
      </template>

      <SmallModal
        :open="errorOpen"
        title="Oof"
        yesText="Go Back"
        :noButton="false"
        @yes="$router.push('/')"
        >{{ error }}</SmallModal
      >
    </div>
  </Header>
</template>
<script>
import Header from "@/components/Header.vue";
import LoginStack from "@/components/LoginStack.vue";
import Spinner from "@/components/Spinner.vue";
import SmallModal from "@/components/SmallModal.vue";

import { API } from "../api";

export default {
  name: "SpaceLogin",
  components: { Header, LoginStack, Spinner, SmallModal },
  data() {
    return {
      loading: true,
      spaceName: "",
      errorOpen: false,
      error: "",
      auth: [],
    };
  },
  watch: {
    $route() {
      this.loadSpaceMeta();
    },
  },
  created() {
    this.loadSpaceMeta();
  },
  methods: {
    loadSpaceMeta() {
      this.loading = true;
      API.get_public_space_meta(this.$route.params.code)
        .then((r) => {
          this.loading = false;
          this.spaceName = r.name;
          this.auth = r.auth;
        })
        .catch((e) => {
          this.loading = false;
          this.errorOpen = true;
          this.error = e;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.space-login {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.space-header {
  font-family: $headingFont;
  font-size: $headingFontSize;
}
</style>