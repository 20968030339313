<template>
  <Settings :title="$store.state.space.space.name" kind="space">
    <template v-slot:nav>
      <NavItem v-model="state" to="general">General</NavItem>
      <NavItem v-model="state" to="roles">Roles</NavItem>
      <NavItem v-model="state" to="assign">Assign Roles</NavItem>
    </template>
    <template v-slot:content>
      <Content to="general" v-model="state">
        <div class="general">
          <div class="field">
            <div class="label">Space Name</div>
            <input type="text" v-model="spaceName" />
          </div>
          <div class="field">
            <div class="label">Authentication Style</div>
            <AuthMode v-model="authStyles"></AuthMode>
          </div>
          <div class="field">
            <div class="label">Welcome Message (leave blank for none)</div>
            <input type="text" v-model="welcomeMessage" />
          </div>
          <div class="tray">
            <BigButton color="black" :loading="generalLoading" @click="saveGeneral">Save</BigButton>
          </div>
        </div>
      </Content>
      <Content to="roles" v-model="state">
        <div class="roles">
          <div class="roles-list">
            <div class="role-menu">
              <div
                class="role-picker"
                :style="color(role, role.id === currentRole)"
                v-for="role in $store.state.space.space.roles"
                :key="role.id"
                @click="currentRole = role.id"
              >{{ role.name }}</div>
            </div>
            <BigButton color="black" @click="newRoleOpen = true">New Role</BigButton>
          </div>
          <div class="role-editor">
            <div class="field" v-if="!currentRoleSettings.default">
              <div class="label">Role Name</div>
              <input type="text" v-model="currentRoleSettings.name" />
            </div>

            <div class="field" v-if="!currentRoleSettings.default && currentRoleSettings.color">
              <div class="label">Color</div>
              <colorPicker :value="currentRoleSettings.color" @input="updateColorValue"></colorPicker>
            </div>

            <div class="field">
              <label>
                <input type="checkbox" v-model="currentRoleSettings.admin" />
                Is Admin? (Override everything)
              </label>
            </div>

            <div class="field">
              <label>
                <input type="checkbox" v-model="currentRoleSettings.create_booths" />
                Can create new tables.
              </label>
            </div>

            <div class="field">
              <label>
                <input type="checkbox" v-model="currentRoleSettings.move_itself" />
                Can join tables.
              </label>
            </div>

            <div class="field">
              <label>
                <input type="checkbox" v-model="currentRoleSettings.create_links" />
                Can create new links.
              </label>
            </div>

            <div class="field">
              <label>
                <input type="checkbox" v-model="currentRoleSettings.move_others" />
                Can move others between tables.
              </label>
            </div>

            <div class="field">
              <label>
                <input type="checkbox" v-model="currentRoleSettings.lock" />
                Can lock/unlock tables.
              </label>
            </div>

            <div class="field">
              <label>
                <input type="checkbox" v-model="currentRoleSettings.use_queue" />
                Can use the queue.
              </label>
            </div>

            <div class="field">
              <label>
                <input type="checkbox" v-model="currentRoleSettings.manage_queue" />
                Can manage the queue.
              </label>
            </div>

            <div class="role-editor-buttons">
              <BigButton color="black" :loading="roleSaveLoading" @click="saveRole">Save</BigButton>
              <BigButton
                color="red"
                @click="deleteRoleOpen = true"
                v-if="!currentRoleSettings.default"
              >Delete Role</BigButton>
            </div>
          </div>
        </div>
      </Content>
      <Content to="assign" v-model="state">
        <Spinner v-if="loadingMembers"></Spinner>
        <div class="members" v-else>
          <div class="member" :key="member.id" v-for="member in members">
            <div class="display-name">
              <div class="display">{{ member.display_name }}</div>
              <div class="email">{{ member.email }}</div>
            </div>
            <div class="assign-roles">
              <div
                class="assign-role"
                :key="role.id"
                v-for="role in member.roles.filter((x) => !x.default)"
                :style="{ backgroundColor: role.color }"
              >
                {{ role.name }}
                <span class="remove-role" @click="removeRole(member, role)">
                  <font-awesome-icon icon="trash"></font-awesome-icon>
                </span>
              </div>
              <div class="assign-role-add" @click="$refs.allRoles.open($event, member)">+</div>
            </div>
          </div>
        </div>
      </Content>

      <ContextMenu ref="allRoles">
        <template slot-scope="{ meta }">
          <div
            v-for="role in $store.state.space.space.roles.filter(
              (x) =>
                !x.default && meta.roles.map((x1) => x1.id).indexOf(x.id) < 0
            )"
            :key="role.id"
            @click="assignRole(meta, role)"
          >
            <span>{{ role.name }}</span>
          </div>
          <div
            class="no-more-roles"
            v-if="
              $store.state.space.space.roles.filter(
                (x) =>
                  !x.default && meta.roles.map((x1) => x1.id).indexOf(x.id) < 0
              ).length == 0
            "
          >
            <span>(No More Roles)</span>
          </div>
        </template>
      </ContextMenu>

      <SmallModal
        :open="errorOpen"
        title="Error"
        noText="Okay"
        :yesButton="false"
        @yes="errorOpen = false"
        @close="errorOpen = false"
      >{{ error }}</SmallModal>

      <SmallModal
        :open="deleteRoleOpen"
        title="Are you sure?"
        yesText="Delete"
        noText="Cancel"
        @yes="deleteRole"
        @close="deleteRoleOpen = false"
      >You can't undo deleting this role.</SmallModal>

      <SmallModal
        :open="newRoleOpen"
        title="New Role"
        yesText="Create"
        noText="Cancel"
        @yes="newRole"
        @close="newRoleOpen = false"
      >
        <div class="new-space">
          <div class="field">
            <div class="label">Role Name</div>
            <input
              type="text"
              ref="namebox"
              data-lpignore="true"
              v-model="newRoleName"
              @keydown.enter="newRole"
            />
          </div>
          <div class="error" v-if="newRoleError != ''">{{ newRoleError }}</div>
        </div>
      </SmallModal>
    </template>
  </Settings>
</template>
<script>
import Settings from "./systems/Settings";
import NavItem from "./systems/NavItem";
import Content from "./systems/Content";
import Spinner from "@/components/Spinner";
import AuthMode from "./AuthMode";
import BigButton from "../BigButton";
import SmallModal from "@/components/SmallModal.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import { Compact } from "vue-color";

import { API } from "../../api";

export default {
  name: "SpaceSettings",
  components: {
    Settings,
    NavItem,
    Content,
    BigButton,
    SmallModal,
    AuthMode,
    Spinner,
    ContextMenu,
    colorPicker: Compact,
  },
  data() {
    return {
      state: "general",
      errorOpen: false,
      error: "",
      generalLoading: false,
      spaceName: "",
      authStyles: [],
      welcomeMessage: "",
      currentRole: -1,
      currentRoleSettings: {},
      newRoleOpen: false,
      newRoleName: "",
      newRoleError: "",
      deleteRoleOpen: false,
      roleSaveLoading: false,
      loadingMembers: false,
      members: [],
    };
  },
  mounted() {
    this.loadSettings();
    this.loadMembers();
  },
  watch: {
    $route() {
      this.loadSettings();
      this.loadMembers();
    },
    state() {
      if (this.state == "assign") this.loadMembers();
    },
    currentRole() {
      this.loadRole();
    },
  },
  methods: {
    loadMembers() {
      this.loadingMembers = true;
      API.get_all_members(this.$route.params.code)
        .then((r) => {
          this.loadingMembers = false;
          this.members = r;
        })
        .catch((e) => {
          this.loadingMembers = false;
          this.errorOpen = true;
          this.error = e;
        });
    },
    loadRole() {
      this.currentRoleSettings = JSON.parse(
        JSON.stringify(
          this.$store.state.space.space.roles.filter(
            (x) => x.id == this.currentRole
          )[0]
        )
      );
    },
    loadSettings() {
      this.$store
        .dispatch("space/loadState", {
          code: this.$route.params.code,
        })
        .then(() => {
          this.spaceName = this.$store.state.space.space.name;
          this.authStyles = this.$store.state.space.space.space.auth_styles;
          this.welcomeMessage = this.$store.state.space.space.space.welcome_message;

          if (this.currentRole === -1) {
            this.currentRole = this.$store.state.space.space.roles[0].id;
          }
          this.loadRole();
        });
    },
    saveGeneral() {
      this.generalLoading = true;
      API.edit_general_space_settings(
        this.$route.params.code,
        this.spaceName,
        this.authStyles,
        this.welcomeMessage,
      )
        .then(() => {
          this.generalLoading = false;
          this.loadSettings();
        })
        .catch((e) => {
          this.errorOpen = true;
          this.error = e;
        });
    },
    saveRole() {
      this.roleSaveLoading = true;
      if (this.currentRoleSettings.default) {
        this.currentRoleSettings.color = "#000000";
      }
      API.update_role(
        this.$route.params.code,
        this.currentRole,
        this.currentRoleSettings.name,
        this.currentRoleSettings.color,
        this.currentRoleSettings.admin,
        this.currentRoleSettings.create_booths,
        this.currentRoleSettings.move_itself,
        this.currentRoleSettings.move_others,
        this.currentRoleSettings.lock,
        this.currentRoleSettings.create_links,
        this.currentRoleSettings.use_queue,
        this.currentRoleSettings.manage_queue
      )
        .then(() => {
          this.roleSaveLoading = false;
          this.loadSettings();
        })
        .catch((e) => {
          this.roleSaveLoading = false;
          this.errorOpen = true;
          this.error = e;
        });
    },
    color(role, selected) {
      let color = "#808080";
      if (role.color) color = role.color;

      const style = { color: color };

      if (selected) {
        style.backgroundColor = color;
        style.color = "white";
      }

      return style;
    },
    updateColorValue(c) {
      this.currentRoleSettings.color = c.hex;
    },
    newRole() {
      API.new_role(this.$route.params.code, this.newRoleName)
        .then((r) => {
          this.newRoleOpen = false;
          this.newRoleName = "";
          this.currentRole = r.id;
          this.loadSettings();
        })
        .catch((e) => {
          this.newRoleError = e;
        });
    },
    deleteRole() {
      API.delete_role(this.$route.params.code, this.currentRole)
        .then(() => {
          this.currentRole = -1;
          this.deleteRoleOpen = false;
          this.loadSettings();
        })
        .catch((e) => {
          this.deleteRoleOpen = false;
          this.errorOpen = true;
          this.error = e;
        });
    },
    assignRole(user, role) {
      API.assign_role(this.$route.params.code, user.id, role.id)
        .then(this.loadMembers)
        .catch((e) => {
          this.errorOpen = true;
          this.error = e;
        });
    },
    removeRole(user, role) {
      API.remove_role(this.$route.params.code, user.id, role.id)
        .then(this.loadMembers)
        .catch((e) => {
          this.errorOpen = true;
          this.error = e;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.member {
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
}

.member .display-name {
  font-weight: bold;
}

.general {
  max-width: 20rem;
}

.tray {
  margin-top: 4rem;
}

.email {
  font-weight: normal;
  font-size: 0.7em;
  color: grey;
}

.roles {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
}

.roles-list {
  text-align: center;
}

.assign-roles {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  font-size: 0.7em;
  color: white;
}

.remove-role {
  cursor: pointer;
  color: rgb(243, 243, 243);
  font-size: 0.7em;
  margin-left: 0.5rem;
}

.assign-role {
  padding: 0.2rem 0.5rem;
  // border: 1px solid grey;
  border-radius: 10px;
  display: inline-block;
  margin-right: 0.4rem;
  display: flex;
  align-items: center;
}

.assign-role-add {
  color: grey;
  border: 1px solid grey;
  border-radius: 100%;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.role-picker {
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.role-picker:hover {
  background-color: rgb(243, 243, 243);
}

.role-menu {
  margin-bottom: 2rem;
}

.error {
  color: red;
}

.role-editor {
  max-width: 20rem;
}

.role-editor-buttons {
  width: 13rem;
  display: flex;
  justify-content: space-between;
}

.no-more-roles {
  font-style: italic;
  cursor: not-allowed !important;
}
</style>