<template>
  <div class="user-dropdown" v-on-clickaway="ca">
    <div class="user-name">{{ $store.state.user.me.name }}</div>
    <div class="user-email">{{ $store.state.user.me.email }}</div>
    <template v-if="isEvent">
      <div class="nav" v-if="!$store.state.user.me.wizard">
        <router-link @click.native="ca" to="/wizard" tag="div" class="nav-item">Pick Genders</router-link>
        <router-link @click.native="ca" to="/schools" tag="div" class="nav-item">Pick Schools</router-link>
        <router-link @click.native="ca" to="/feedback" tag="div" class="nav-item">Feedback</router-link>
        <a @click="ca" href="mailto:xoxo-app@mit.edu">
          <div class="nav-item">Contact Us</div>
        </a>
      </div>
      <div class="logout">
        <a href="/api/event/logout">
          <BigButton color="black">Logout</BigButton>
        </a>
      </div>
    </template>
    <template v-if="!isEvent">
      <!-- <div class="tier-badge">
        <TierBadge></TierBadge>
      </div>-->
      <div class="nav">
        <router-link
          :to="{name: 'spacesettings', params: {code: $route.params.code}}"
          v-if="($route.name == 'space' || $route.name == 'profile') && $store.state.space.space.admin"
        >
          <div class="nav-item">Space Settings</div>
        </router-link>
        <router-link
          :to="{name: 'profile', params: {code: $route.params.code}}"
          v-if="$route.name == 'space' || $route.name == 'profile'"
        >
          <div class="nav-item">Edit Profile</div>
        </router-link>
        <router-link
          to="/spaces"
          target="_blank"
          v-if="$route.name == 'space' || $route.name == 'profile'"
        >
          <div class="nav-item">
            My Spaces
            <font-awesome-icon class="external" icon="external-link-alt"></font-awesome-icon>
          </div>
        </router-link>
        <router-link to="/spaces" v-else>
          <div class="nav-item">My Spaces</div>
        </router-link>
        <!-- <a @click="ca" href="mailto:sales@mingle.sh" v-if="!$store.state.user.me.premium">
          <div class="nav-item">Pricing</div>
        </a>-->
        <a @click="ca" href="mailto:help@firemeet.io">
          <div class="nav-item">Contact Us</div>
        </a>
      </div>
      <div class="logout">
        <a href="/api/logout">
          <BigButton color="black">Logout</BigButton>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import BigButton from "@/components/BigButton.vue";
// import TierBadge from "@/components/TierBadge.vue";
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  name: "UserDropdown",
  components: { BigButton },
  methods: {
    ca() {
      this.$emit("clickabroad");
    }
  },
  computed: {
    isEvent() {
      return false;
    }
  }
};
</script>

<style lang="scss">
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.user-dropdown {
  position: absolute;
  right: 1rem;
  top: 3.5rem;
  background-color: white;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: solid 1px #e0e0e0;
  padding: 1rem;
  z-index: 2;
}

.logout {
  margin-top: 2rem;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-name {
  font-weight: bold;
}

.user-email {
  font-size: 15px;
  color: $gray;
}

.external {
  margin-left: 0.5rem;
  font-size: 10px;
  color: rgb(206, 206, 206);
}

.nav {
  margin-top: 1rem;
}

.nav-item {
  cursor: pointer;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0.5rem 1.5rem;
  font-size: 15px;
  border-top: 1px solid #e9e8e8;
  color: #3e3e3e;
}

.nav-item:nth-last-child(1) {
  border-bottom: 1px solid #e9e8e8;
}

.nav-item:hover {
  background-color: #f1f1f1;
}
</style>
