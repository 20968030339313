<template>
  <div class="initials" :style="{background: color}">{{ letters }}</div>
</template>
<script>
const colorArray = [
  "#664980",
  "#4C709A",
  "#569556",
  "#B7AA5E",
  "#A66A40",
  "#9B4244"
];

export default {
  name: "Initials",
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    letters() {
      var initials = this.name.match(/\b\w/g) || [];
      return ((initials.shift() || "") + (initials.pop() || ""))
        .toUpperCase()
        .substring(0, 2);
    },
    color() {
      const index =
        Math.abs(
          this.name.split("").reduce(function(a, b) {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
          }, 0)
        ) % colorArray.length;
      return colorArray[index];
    }
  }
};
</script>
<style lang="scss" scoped>
.initials {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  color: white;
  border: 2px solid rgb(139, 89, 255);
}
</style>