import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import constants from '@/store/modules/constants'
import date from '@/store/modules/date'
import events from '@/store/modules/events'
import space from '@/store/modules/space'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        user,
        constants,
        date,
        events,
        space
    },
    strict: debug,
})