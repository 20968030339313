<template>
  <div class="footer">
    <div class="footer-inner">
      <div class="footer-title">
        <Logo :bw="true"></Logo>
      </div>
      <div
        class="btt"
        v-scroll-to="{
          el: '#top',
          duration: 500,
          easing: 'ease',
          offset: -100,
        }"
      >
        <font-awesome-icon icon="arrow-up"></font-awesome-icon>Back To Top
      </div>
      <div class="footer-bottom">
        <div class="copyright">© Shreyas Kapur 2020</div>
        <div>
          <a href="/terms" target="_blank" rel="noreferrer">Terms of Service</a>
        </div>
        <div>
          <a href="/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";

export default {
  name: "Footer",
  components: { Logo },
};
</script>
<style lang="scss" scoped>
.footer {
  //   border-top: 1px solid black;
  background: rgb(226, 226, 226);
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: rgb(65, 63, 75);
}

.footer-inner {
  max-width: 60rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0rem 5rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    "logo btt"
    "bottom bottom";
  grid-template-rows: auto auto;
}

@media (max-width: $breakpoint-mobile) {
  .footer-inner {
    grid-template-columns: 100%;
    grid-template-areas:
      "logo"
      "btt"
      "bottom";
    grid-template-rows: auto auto auto;
  }
}

.btt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 17px;
  font-weight: bold;
  color: #436cf2;
  cursor: pointer;
}

@media (max-width: $breakpoint-mobile) {
  .btt {
    justify-content: center;
  }

  .footer-title {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
}

.btt svg {
  margin-right: 0.5rem;
}

.footer-bottom {
  display: flex;
  font-size: 13px;
  margin-top: 1.5rem;
  grid-area: bottom;
}

.footer-bottom div {
  margin-right: 2rem;
}

@media (max-width: $breakpoint-mobile) {
  .footer-bottom {
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
  }
  .footer-bottom div {
    margin-right: 0rem;
    margin-bottom: 0.5rem;
  }
}

.footer-bottom a {
  transition: 0.2s ease;
  color: rgb(65, 63, 75);
}

.footer-bottom a:hover {
  color: #436cf2;
}
</style>