<template>
  <div
    class="booth"
    @dragenter.prevent="dragEnter"
    @dragover.prevent
    @dragleave.prevent="dragLeave"
    @drop="drop"
    :class="{ locked: booth.locked }"
  >
    <div class="name">
      <div class="name-text">
        <span class="hash">#</span>
        {{ booth.name }}
        <span
          class="raise-hand"
          v-tooltip="'Request Help'"
          @click="raiseHand"
          :class="{ active: booth.hand_raised !== null }"
        >
          <font-awesome-icon icon="hand-paper"></font-awesome-icon>
        </span>
        <span class="timing" v-if="booth.hand_raised !== null">{{ handRaisedTime }}</span>
      </div>
      <div class="cog">
        <font-awesome-icon
          icon="lock"
          v-tooltip.top="'Unlock this table'"
          v-if="booth.locked"
          @click="lock"
        ></font-awesome-icon>
        <font-awesome-icon icon="lock-open" v-tooltip.top="'Lock this table'" @click="lock" v-else></font-awesome-icon>
        <font-awesome-icon
          icon="pen"
          v-tooltip.top="'Rename'"
          @click="
            renameOpen = true;
            renameName = booth.name;
          "
        ></font-awesome-icon>
        <font-awesome-icon icon="trash" v-tooltip.top="'Delete'" @click="deleteOpen = true"></font-awesome-icon>
      </div>
    </div>
    <div class="bubbles" ref="bubbles">
      <div
        class="bubble"
        v-if="
          $store.state.space.space.booth &&
          $store.state.space.space.booth.id == booth.id
        "
      >
        <div class="join leave" @click="$emit('leave')">
          <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
        </div>
        <div class="bubble-name">Leave</div>
      </div>
      <div class="bubble" v-else>
        <div class="join" @click="join">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </div>
        <div class="bubble-name">Join</div>
      </div>
      <UserBubble v-for="user in booth.users" :key="user.id" :user="user"></UserBubble>
      <!-- <div
        class="bubble"
        draggable="true"
        v-for="user in booth.users"
        :key="user.id"
        @dragstart="startDrag($event, user)"
        @dragend="dragging = false"
        @contextmenu.prevent="$refs.userMenu.open($event, user)"
      >
        <div class="profile-picture">
          <ProfilePicture :user="user"></ProfilePicture>
        </div>
        <div class="bubble-name">{{ user.display_name }}</div>
      </div>-->
      <div class="bubble drop" v-if="dropping"></div>
    </div>

    <SmallModal
      :open="error != ''"
      title="Error"
      yesText="Okay"
      @yes="error = ''"
      @close="error = ''"
    >{{ error }}</SmallModal>

    <SmallModal
      :open="renameOpen"
      title="Rename Table"
      yesText="Rename"
      noText="Cancel"
      @yes="rename"
      @close="
        renameOpen = false;
        reload();
      "
    >
      <div class="rename">
        <div class="field">
          <div class="label">Table Name</div>
          <input type="text" data-lpignore="true" v-model="renameName" @keydown.enter="rename" />
        </div>
        <div class="error" v-if="renameError != ''">{{ renameError }}</div>
      </div>
    </SmallModal>

    <SmallModal
      :open="deleteOpen"
      title="Are you sure?"
      yesText="Delete"
      noText="Cancel"
      @yes="
        deleteOpen = false;
        deleteMe();
      "
      @close="deleteOpen = false"
    >You can't undo this.</SmallModal>

    <ContextMenu ref="userMenu">
      <template slot-scope="{ meta }">
        <div @click="moveToMyTable(meta)">
          <span>Move To My Table</span>
        </div>
        <div @click="kick(meta)">
          <span>Kick</span>
        </div>
      </template>
    </ContextMenu>
  </div>
</template>
<script>
// import ProfilePicture from "@/components/ProfilePicture.vue";
import SmallModal from "@/components/SmallModal.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import UserBubble from "@/components/UserBubble.vue";
import moment from "moment-timezone";
import { API } from "../api";
// import { wrapGrid } from "animate-css-grid";

export default {
  name: "Booth",
  components: { SmallModal, ContextMenu, UserBubble },
  props: {
    booth: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dragging: false,
      dropping: false,
      enterTarget: 0,
      error: "",
      renameOpen: false,
      renameError: "",
      renameName: "",
      deleteOpen: false,
      now: Date.now(),
      nowInterval: undefined
    };
  },
  computed: {
    handRaisedTime() {
      this.now;
      if (this.booth.hand_raised === null) return "";
      return moment(this.booth.hand_raised).fromNow();
    }
  },
  methods: {
    raiseHand() {
      API.raise_hand(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.booth.id,
        this.booth.hand_raised === null
      )
        .then(this.reload)
        .catch(this.toastError);
    },
    join() {
      API.join_booth(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.booth.id
      )
        .then(() => {
          this.reload();
        })
        .catch(this.toastError);
    },
    reload() {
      // this.$store.dispatch("space/loadState", {
      //   code: this.$route.params.code,
      // });
    },
    startDrag(event, user) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("user", JSON.stringify(user));
      this.dragging = true;
    },
    dragEnter(e) {
      if (this.dragging) return;

      this.enterTarget = e.target;
      this.dropping = true;
    },
    dragLeave(e) {
      if (this.dragging) return;

      if (this.enterTarget == e.target) this.dropping = false;
    },
    drop(e) {
      const user = JSON.parse(e.dataTransfer.getData("user"));
      this.dropping = false;
      API.move_user(
        this.$route.params.code,
        user.id,
        this.$store.state.space.space.room.id,
        this.booth.id
      )
        .then(this.reload)
        .catch(e => {
          this.$toasted.show(e, {
            position: "bottom-center",
            duration: 3000,
            theme: "bubble",
            action: {
              text: "Dismiss",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          });
        });
    },
    moveToMyTable(user) {
      if (this.$store.state.space.space.booth == null) {
        this.$toasted.show("You're not in any table.", {
          position: "bottom-center",
          duration: 3000,
          theme: "bubble",
          action: {
            text: "Dismiss",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        });
        return;
      }

      API.move_user(
        this.$route.params.code,
        user.id,
        this.$store.state.space.space.room.id,
        this.$store.state.space.space.booth.id
      )
        .then(this.reload)
        .catch(e => {
          this.$toasted.show(e, {
            position: "bottom-center",
            duration: 3000,
            theme: "bubble",
            action: {
              text: "Dismiss",
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          });
        });
    },
    kick(user) {
      API.move_user(
        this.$route.params.code,
        user.id,
        this.$store.state.space.space.room.id,
        -1
      )
        .then(this.reload)
        .catch(this.toastError);
    },
    lock() {
      API.lock_booth(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.booth.id,
        !this.booth.locked
      )
        .then(this.reload)
        .catch(this.toastError);
    },
    rename() {
      API.rename_booth(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.booth.id,
        this.renameName
      )
        .then(() => {
          this.renameOpen = false;
          this.reload();
        })
        .catch(e => {
          this.renameError = e;
        });
    },
    deleteMe() {
      API.delete_booth(
        this.$route.params.code,
        this.$store.state.space.space.room.id,
        this.booth.id
      )
        .then(this.reload)
        .catch(this.toastError);
    },
    toastError(e) {
      this.$toasted.show(e, {
        position: "bottom-center",
        duration: 3000,
        theme: "bubble",
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      });
    }
  },
  mounted() {
    // wrapGrid(this.$refs.bubbles);
  },
  created() {
    this.nowInterval = setInterval(() => {
      this.now = Date.now();
    }, 1000);
  },
  beforeDestroy() {
    if (this.nowInterval) clearInterval(this.nowInterval);
  }
};
</script>
<style lang="scss" scoped>
.booth {
  user-select: none;
}

.locked {
  background-image: linear-gradient(
    45deg,
    #ee617d5e 25%,
    transparent 25%,
    transparent 50%,
    #ee617d5e 50%,
    #ee617d5e 75%,
    transparent 75%,
    transparent 100%
  );
  background-size: 40px 40px;
}

.name {
  background: #2d2b38;
  color: rgb(194, 194, 194);
  padding: 0.5rem 1.5rem;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.cog {
  opacity: 0;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5rem;
}

.raise-hand {
  color: rgb(98, 98, 98);
  padding: 0 0.5rem;
  font-size: 14px;
}

.raise-hand.active {
  color: rgb(206, 183, 255);
}

.cog svg:hover {
  color: rgb(209, 209, 209);
}

.name:hover .cog {
  opacity: 1;
}

.profile-picture {
  width: 3rem;
  height: 3rem;
}

.bubbles {
  display: grid;
  grid-template-columns: repeat(auto-fit, 5rem);
}

.hash {
  font-weight: lighter;
  color: rgb(77, 77, 77);
  margin-right: 0.3rem;
}

.bubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(145, 145, 145);
  font-size: 14px;
  margin-top: 1rem;
  cursor: pointer;
  background: transparent;
  animation: pop 0.1s;
}

.bubble.drop {
  transition: 3ms ease-in;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23355' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 4rem;
}

.bubble .bubble-name {
  margin-top: 0.5rem;
  //   background: rgba(0, 0, 0, 0.253);
  border-radius: 5px;
  padding: 2px 10px;
  text-align: center;
}

.bubble img {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 2px solid rgb(139, 89, 255);
}

.bubble .join {
  background: rgb(175, 54, 74);
  // background: rgb(107, 117, 202);
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  color: white;
}

.leave {
  background: rgb(107, 117, 202) !important;
  // background: rgb(175, 54, 74) !important;
}

.timing {
  color: grey;
  font-size: 14px;
}

.initials {
  width: 3rem;
  height: 3rem;
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>