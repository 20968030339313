import {
    API
} from '../../api';

const state = {
    space: {},
    loading: true,
    disconnected: false,
}

// getters
const getters = {

}

// actions
const actions = {
    loadState(context, {
        code
    }) {
        return new Promise((resolve, reject) => {
            context.commit('setLoading', true);
            API.get_state(code).then((r) => {
                if (r.owner !== undefined) {
                    r.online.forEach((user) => {
                        user.roles = user.roles.map(id => {
                            return r.roles.filter(x => x.id === id)[0];
                        });
                    });

                    if (r.booths != null) {
                        r.booths.forEach(booth => {
                            booth.users = booth.users.map(id => {
                                return r.online.filter(x => x.id === id)[0];
                            })
                        })
                    }

                    r.user = r.online.filter(x => x.id === r.user)[0];

                    if (r.booth !== null) {
                        r.booth = r.booths.filter(x => x.id === r.booth)[0];
                    }

                    if (r.queue.members !== undefined) {
                        r.queue.members.forEach((member) => {
                            const users = r.online.filter(x => x.id === member.user);
                            if (users.length === 0) {
                                member.exists = false;
                                return;
                            }
                            else {
                                member.user = users[0];
                                member.exists = true;
                            }

                            if (member.claimed_by === null) return;

                            const users2 = r.online.filter(x => x.id === member.claimed_by);
                            if (users2.length === 0) member.exists = false;
                            else {
                                member.claimed_by = users2[0];
                                member.exists = true;
                            }
                        });

                        r.queue.members = r.queue.members.filter(x => x.exists);
                    }
                }

                context.commit('setSpace', r);
                context.commit('setLoading', false);
                context.commit('setDisconnected', false);
                resolve();
            }).catch(() => {
                context.commit('setSpace', {});
                context.commit('setLoading', false);
                context.commit('setDisconnected', true);
                reject();
            });
        });
    },
}

// mutations
const mutations = {
    setSpace(state, space) {
        state.space = space;
    },
    setLoading(state, l) {
        state.loading = l;
    },
    setDisconnected(state, l) {
        state.disconnected = l;
    },
    addOnlineUser(state, user) {
        if (state.space.online) {
            const exists = state.space.online.some(o => o.id === user.id);
            if (exists) return;
            user.roles = user.roles.map(id => {
                return state.space.roles.filter(x => x.id === id)[0];
            });
            state.space.online.push(user);
        }
    },
    setStateSocket(state, socketState) {
        const myUserID = state.space.user.id;

        socketState.online.forEach((user) => {
            user.roles = user.roles.map(id => {
                return socketState.roles.filter(x => x.id === id)[0];
            });
        });

        socketState.user = socketState.online.filter(x => x.id === myUserID)[0];
        socketState.name = socketState.space.name;
        socketState.queue = { locked: true, members: [], am_i_in_queue: false };
        socketState.owner = socketState.owner == this.state.user.me.id;
        socketState.admin = socketState.owner || socketState.user.roles.some(role => role.admin);

        let myBooth = null;

        if (socketState.booths != null) {
            socketState.booths.forEach(booth => {
                if (booth.users.some(user => user === myUserID)) {
                    myBooth = booth.id;
                }
                booth.users = booth.users.map(id => {
                    return socketState.online.filter(x => x.id === id)[0];
                })
            })
        }

        socketState.booth = myBooth;

        if (socketState.booth !== null) {
            socketState.booth = socketState.booths.filter(x => x.id === socketState.booth)[0];
            socketState.uuid = socketState.booth.uuid;
            socketState.tabs = socketState.booth.tabs;
        }

        state.space = socketState;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}