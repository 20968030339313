import Vue from 'vue'

import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import {
    faEllipsisV
} from '@fortawesome/free-solid-svg-icons/faEllipsisV'
import {
    faHeart
} from '@fortawesome/free-solid-svg-icons/faHeart'
import {
    faFlag
} from '@fortawesome/free-solid-svg-icons/faFlag'
import {
    faCaretRight
} from '@fortawesome/free-solid-svg-icons/faCaretRight'
import {
    faCaretLeft
} from '@fortawesome/free-solid-svg-icons/faCaretLeft'
import {
    faCaretDown
} from '@fortawesome/free-solid-svg-icons/faCaretDown'
import {
    faTimes
} from '@fortawesome/free-solid-svg-icons/faTimes'
import {
    faTrash
} from '@fortawesome/free-solid-svg-icons/faTrash'
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons/faPlus'
import {
    faPen
} from '@fortawesome/free-solid-svg-icons/faPen'
import {
    faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import {
    faEye
} from '@fortawesome/free-solid-svg-icons/faEye'
import {
    faCog
} from '@fortawesome/free-solid-svg-icons/faCog'
import {
    faSignOutAlt
} from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import {
    faUniversity
} from '@fortawesome/free-solid-svg-icons/faUniversity'
import {
    faGoogle
} from '@fortawesome/free-brands-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import {
    faLock
} from '@fortawesome/free-solid-svg-icons/faLock'
import {
    faLockOpen
} from '@fortawesome/free-solid-svg-icons/faLockOpen'
import {
    faLink
} from '@fortawesome/free-solid-svg-icons/faLink'
import {
    faHandPaper
} from '@fortawesome/free-solid-svg-icons/faHandPaper'
import {
    faCheck
} from '@fortawesome/free-solid-svg-icons/faCheck'
import {
    faBullhorn
} from '@fortawesome/free-solid-svg-icons/faBullhorn'
import {
    faAngleDown
} from '@fortawesome/free-solid-svg-icons/faAngleDown'
import {
    faArrowUp
} from '@fortawesome/free-solid-svg-icons/faArrowUp'
import {
    faVolumeUp
} from '@fortawesome/free-solid-svg-icons/faVolumeUp'
import {
    faVolumeMute
} from '@fortawesome/free-solid-svg-icons/faVolumeMute'

library.add(faMapMarkerAlt)
library.add(faEllipsisV)
library.add(faHeart)
library.add(faFlag)
library.add(faCaretLeft)
library.add(faCaretRight)
library.add(faCaretDown)
library.add(faTimes)
library.add(faPlus)
library.add(faGoogle)
library.add(faPen)
library.add(faTrash)
library.add(faCog)
library.add(faExternalLinkAlt)
library.add(faEye)
library.add(faSignOutAlt)
library.add(faUniversity)
library.add(faLock)
library.add(faLockOpen)
library.add(faLink)
library.add(faHandPaper)
library.add(faCheck)
library.add(faBullhorn)
library.add(faAngleDown)
library.add(faArrowUp)
library.add(faVolumeUp)
library.add(faVolumeMute)

Vue.component('font-awesome-icon', FontAwesomeIcon)