<template>
  <div
    class="context-wrapper"
    v-if="visible"
    v-on-clickaway="away"
    ref="menuBody"
    :style="style()"
    @click="away"
    tabindex="-1"
    @contextmenu.capture.prevent
  >
    <slot v-bind:meta="meta"></slot>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";

function px(val) {
  return val + "px";
}

export default {
  name: "ContextMenu",
  mixins: [clickaway],
  data: function() {
    return {
      visible: false,
      clickEvent: {},
      meta: "none"
    };
  },
  methods: {
    open: function(e, meta) {
      this.clickEvent = e;
      this.visible = true;
      this.meta = meta;

      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    away: function() {
      if (this.visible) this.visible = false;
    },
    style: function() {
      const x = this.clickEvent.pageX;
      const y = this.clickEvent.pageY;

      let width = 10;
      let height = 10;

      if (this.$refs.menuBody) {
        width = this.$refs.menuBody.offsetWidth;
        height = this.$refs.menuBody.offsetHeight;
      }

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      if (y + height <= windowHeight && x + width <= windowWidth) {
        return {
          left: px(x),
          top: px(y)
        };
      }

      if (y - height >= 0 && x + width <= windowWidth) {
        return {
          left: px(x),
          bottom: px(windowHeight - y)
        };
      }

      if (y - height >= 0 && x - width >= 0) {
        return {
          right: px(windowWidth - x),
          bottom: px(windowHeight - y)
        };
      }

      if (y + height <= windowHeight && x - width >= 0) {
        return {
          right: px(windowWidth - x),
          top: px(y)
        };
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.context-wrapper {
  position: absolute;
  background: #272629;
  z-index: 100000;
  padding: 0.5rem 0;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border: solid 1px #000000;
  color: #c1c1c1;
  font-size: 13px;
  font-weight: 400;
  max-height: 30rem;
  overflow-y: auto;
  border-radius: 10px;
}

.context-wrapper .a {
  text-decoration: none;
}

.context-wrapper > div {
  padding: 0.3rem 0.8rem;
  display: grid;
  cursor: pointer;
  grid-template-rows: auto;
  grid-template-columns: 1rem 3fr 1fr;
  grid-template-areas: "icon text shortcut";
  grid-column-gap: 0.2rem;
}

.context-wrapper > div:hover {
  background-color: #383838;
}

.context-wrapper > div.disabled {
  color: grey;
  cursor: not-allowed;
}

.context-wrapper > div.disabled:hover {
  background-color: white;
}

.context-wrapper > div > i {
  color: #767676;
  grid-area: icon;
  align-self: center;
}

.context-wrapper > div > span:nth-of-type(1) {
  grid-area: text;
}

.context-wrapper > div > span:nth-of-type(2) {
  grid-area: shortcut;
  color: #767676;
  align-self: flex-end;
}
</style>