export class Core {
    static makeRequest(route, body) {
        return new Promise((resolve, reject) => {
            fetch(route, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            }).then((r) => {
                r.json().then((data) => {
                    if (data.result != null && "error" in data.result) {
                        reject(data.result.error);
                        return;
                    }
                    resolve(data.result);
                });
            }).catch((e) => {
                reject(e);
            })
        });
    }
}

export class API {
    static get_spaces () {

    return Core.makeRequest("/api/spaces", {
        });
}

static new_event (name,) {

    return Core.makeRequest("/api/spaces/new", {
        name: name,
        });
}

static get_public_space_meta (code,) {

    return Core.makeRequest("/api/spaces/public", {
        code: code,
        });
}

static get_state (code,) {

    return Core.makeRequest("/api/space/state", {
        code: code,
        });
}

static set_profile (code,display_name,) {

    return Core.makeRequest("/api/space/set_profile", {
        code: code,
        display_name: display_name,
        });
}

static new_booth (code,room_id,booth_name,) {

    return Core.makeRequest("/api/space/new_booth", {
        code: code,
        room_id: room_id,
        booth_name: booth_name,
        });
}

static new_room (code,room_name,) {

    return Core.makeRequest("/api/space/new_room", {
        code: code,
        room_name: room_name,
        });
}

static rename_booth (code,room_id,booth_id,name,) {

    return Core.makeRequest("/api/booth/rename", {
        code: code,
        room_id: room_id,
        booth_id: booth_id,
        name: name,
        });
}

static join_booth (code,room_id,booth_id,) {

    return Core.makeRequest("/api/booth/join", {
        code: code,
        room_id: room_id,
        booth_id: booth_id,
        });
}

static raise_hand (code,room_id,booth_id,raised,) {

    return Core.makeRequest("/api/booth/hand_raise", {
        code: code,
        room_id: room_id,
        booth_id: booth_id,
        raised: raised,
        });
}

static delete_booth (code,room_id,booth_id,) {

    return Core.makeRequest("/api/booth/delete", {
        code: code,
        room_id: room_id,
        booth_id: booth_id,
        });
}

static delete_all_booth (code,room_id,) {

    return Core.makeRequest("/api/booth/delete_all", {
        code: code,
        room_id: room_id,
        });
}

static lock_booth (code,room_id,booth_id,locked,) {

    return Core.makeRequest("/api/booth/lock", {
        code: code,
        room_id: room_id,
        booth_id: booth_id,
        locked: locked,
        });
}

static move_user (code,user_id,room_id,booth_id,) {

    return Core.makeRequest("/api/booth/move", {
        code: code,
        user_id: user_id,
        room_id: room_id,
        booth_id: booth_id,
        });
}

static leave_booth (code,) {

    return Core.makeRequest("/api/booth/leave", {
        code: code,
        });
}

static room_join (code,room_id,) {

    return Core.makeRequest("/api/room/join", {
        code: code,
        room_id: room_id,
        });
}

static room_leave (code,) {

    return Core.makeRequest("/api/room/leave", {
        code: code,
        });
}

static edit_general_space_settings (code,space_name,auth_styles,welcome_message,) {

    return Core.makeRequest("/api/space/settings/general", {
        code: code,
        space_name: space_name,
        auth_styles: auth_styles,
        welcome_message: welcome_message,
        });
}

static get_all_members (code,) {

    return Core.makeRequest("/api/space/members", {
        code: code,
        });
}

static assign_role (code,user_id,role_id,) {

    return Core.makeRequest("/api/space/assign_role", {
        code: code,
        user_id: user_id,
        role_id: role_id,
        });
}

static remove_role (code,user_id,role_id,) {

    return Core.makeRequest("/api/space/remove_role", {
        code: code,
        user_id: user_id,
        role_id: role_id,
        });
}

static new_link (code,room_id,link_name,link,) {

    return Core.makeRequest("/api/space/new_link", {
        code: code,
        room_id: room_id,
        link_name: link_name,
        link: link,
        });
}

static delete_link (code,room_id,link_id,) {

    return Core.makeRequest("/api/link/delete", {
        code: code,
        room_id: room_id,
        link_id: link_id,
        });
}

static lock_unlock_queue (code,room_id,locked,) {

    return Core.makeRequest("/api/queue/lock", {
        code: code,
        room_id: room_id,
        locked: locked,
        });
}

static clear_queue (code,room_id,) {

    return Core.makeRequest("/api/queue/clear", {
        code: code,
        room_id: room_id,
        });
}

static add_myself_to_queue (code,room_id,subtext,) {

    return Core.makeRequest("/api/queue/add", {
        code: code,
        room_id: room_id,
        subtext: subtext,
        });
}

static claim_on_queue (code,room_id,user_id,) {

    return Core.makeRequest("/api/queue/claim", {
        code: code,
        room_id: room_id,
        user_id: user_id,
        });
}

static unclaim_on_queue (code,room_id,user_id,) {

    return Core.makeRequest("/api/queue/unclaim", {
        code: code,
        room_id: room_id,
        user_id: user_id,
        });
}

static delete_from_queue (code,room_id,user_id,) {

    return Core.makeRequest("/api/queue/delete", {
        code: code,
        room_id: room_id,
        user_id: user_id,
        });
}

static remove_me_from_queue (code,room_id,) {

    return Core.makeRequest("/api/queue/remove/me", {
        code: code,
        room_id: room_id,
        });
}

static new_tab (code,booth_id,tab_type,) {

    return Core.makeRequest("/api/tabs/new", {
        code: code,
        booth_id: booth_id,
        tab_type: tab_type,
        });
}

static delete_tab (code,booth_id,tab_id,) {

    return Core.makeRequest("/api/tabs/delete", {
        code: code,
        booth_id: booth_id,
        tab_id: tab_id,
        });
}

static new_role (code,role_name,) {

    return Core.makeRequest("/api/space/role/new", {
        code: code,
        role_name: role_name,
        });
}

static delete_role (code,role_id,) {

    return Core.makeRequest("/api/space/role/delete", {
        code: code,
        role_id: role_id,
        });
}

static update_role (code,role_id,name,color,is_admin,create_table,move_itself,move_others,lock,create_links,use_queue,manage_queue,) {

    return Core.makeRequest("/api/space/role/update", {
        code: code,
        role_id: role_id,
        name: name,
        color: color,
        is_admin: is_admin,
        create_table: create_table,
        move_itself: move_itself,
        move_others: move_others,
        lock: lock,
        create_links: create_links,
        use_queue: use_queue,
        manage_queue: manage_queue,
        });
}
}